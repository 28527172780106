import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  styled,
  Typography,
} from '@mui/material'
import { ListTable } from '../../../../components/tables'
import { SearchInputBasic } from '../../../../components/inputs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import { toast } from 'react-toastify'
import lightTheme from '../../../../styles/theme/lightTheme'
import { useNavigate, useParams } from 'react-router-dom'
import {
  faArrowLeft,
  faCalendarAlt,
  faCirclePlus,
  faDownload,
  faEdit,
  faMapMarker,
  faPenCircle,
} from '@fortawesome/pro-solid-svg-icons'
import DetailCandidat from '../../../../components/modals/DetailCandidat'
import { combineAndSetStatus, debounce } from '../../../../config'
import { useTranslation } from 'react-i18next'
import {
  originLeadList,
  prioriteEntrepriselList,
  STATUS_CONTRAT,
} from '../../../../data'
import moment from 'moment'
import EditBesoinEntreprise from '../../../../components/modals/EditBesoinEntreprise'
import CvthequeModal from '../../../../components/modals/CvthequeModal'
import DownloadBesoinEntreprise from '../../../../components/modals/DownloadBesoinEntreprise'

const WrapperButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))
const WrapperIcons = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: '8px',
}))

const ItemLong = styled(Paper)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  padding: '15px',
}))

const BannerCircle = styled(Box)(({ theme }) => ({
  backgroundColor: '#DFB02D',
  height: 50,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '30px',

  '.firstCircle, .secondCircle, .thirdCircle, .fourthCircle, .fiveCircle': {
    width: 10,
    height: 10,
    backgroundColor: lightTheme.palette.secondary.main,
    border: '1px solid #FFFFFF',
    borderRadius: '50%',
  },
}))

const TitleBox = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 14,
  lineHeight: '30px',
  letterSpacing: '0.4px',
  color: lightTheme.palette.primary.b2,
  textTransform: 'uppercase',
  minHeight: '30px',
}))

const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const IconContainer = styled(Box)(({ theme }) => ({
  width: 80,
  height: 30,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  '&:hover': {
    borderWidth: '2px',
  },

  '&:active': {
    borderWidth: '1px',
  },
}))

const ProfilContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '45px',
  justifyContent: 'space-between',
}))

const WrapperProfilText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  rowGap: '5px',
}))

const BannerProfil = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 40,
  backgroundColor: lightTheme.palette.primary.b6,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
}))

const WrapperBanner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '10px',
}))

const BannerProfilContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '10px',
}))

const MatchingCandidate = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [candidates, setCandidates] = useState([])
  const [candidatSelectionner, setCandidatSelectionner] = useState([])
  const [candidatRetenu, setCandidatRetenur] = useState([])
  const [loading, setLoading] = useState(false)
  const [openDetailCandidat, setOpenDetailCandidat] = useState(false)
  const [id, setId] = useState(null)
  const [candidat, setCandidat] = useState(null)
  const [search, setSearch] = useState('')
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [userId, setUserId] = useState()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [besoinEntreprise, setBesoinEntreprise] = useState()

  const handleSearchChange = debounce((event) => {
    const { value } = event.target
    setSearch(value)
  }, 800)

  const handleDetailCandidat = (candidat) => {
    setOpenDetailCandidat(true)
    setCandidat(candidat)
  }
  const handleCloseDetailCandidat = () => {
    setOpenDetailCandidat(false)
    //fetchData()
  }

  const params = useParams()

  useEffect(() => {
    getDetailBesoin(params.besoinId)
  }, [params.besoinId])

  const getDetailBesoin = async (besoinId) => {
    let payload = {
      search: search,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    setLoading(true)

    try {
      const result = await Api.besoinEntreprise.matchingCandidatesByOffre(
        besoinId
      )

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setBesoinEntreprise(res.data.besoin)
        setCandidates(res.data.candidats)

        setCandidatSelectionner(
          combineAndSetStatus(
            res.data.besoin.candidats,
            res.data.besoin.candidatsPresenter
          )
        )

        setCandidatRetenur(res.data.besoin.candidatsRetenu)
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoading(false)
  }

  const handleClickToProfil = (candidatId, candidat) => {


    window.open(
      `/admin/gestion-prospect/profil-prospect-candidat/${candidatId}`,
      '_blank'
    )
    //handleDetailCandidat(candidat)
  }

  const handleRemoveProfil = (idUser) => {
    setOpen(true)
    setUserId(idUser)
  }

  const [seeMore, setSeeMore] = useState(false)

  const handleSeeMore = (event) => {
    setSeeMore(!seeMore)
  }

  const handleback = (event) => {
    navigate(-1)
  }

  const [openBesoin, setOpenBesoin] = useState(false)
  const [openDownloadBesoin, setOpenDownloadBesoin] = useState(false)
  const [besoin, setBesoin] = useState(null)
  const handleEditBesoin = (besoin) => {
    setOpenBesoin(true)
    setBesoin(besoin)
  }

  const handleCloseBesoin = () => {
    setOpenBesoin(false)
    setOpenDownloadBesoin(false)
    setBesoin()
  }

  const handleSaveBesoin = () => {
    setOpenBesoin(false)
    setOpen(false)
    getDetailBesoin(params.besoinId)
  }

  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <>
      <div style={{ width: '100%' }}>
        <Button
          sx={{
            margin: '10px',
            position: 'absolute',
          }}
          onClick={handleback}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            fontSize={15}
            color={lightTheme.palette.primary.b2}
          />
          <Typography
            variant={'body2'}
            color={lightTheme.palette.primary.b2}
            textAlign={'right'}
            marginLeft={1}
          >
            {'Retour'}
          </Typography>
        </Button>
        <div
          style={{
            width: '100%',
            textAlign: 'center',
          }}
        >
          <Button
            sx={{
              margin: '10px',
            }}
            onClick={handleEditBesoin}
          >
            <FontAwesomeIcon
              icon={faEdit}
              fontSize={15}
              color={lightTheme.palette.primary.b2}
            />
            <Typography
              variant={'body2'}
              color={lightTheme.palette.primary.b2}
              textAlign={'right'}
              marginLeft={1}
            >
              {'Modifier la fiche'}
            </Typography>
          </Button>

          <Button
            sx={{
              margin: '10px',
            }}
            onClick={() => setOpenDownloadBesoin(true)}
          >
            <FontAwesomeIcon
              icon={faDownload}
              fontSize={15}
              color={lightTheme.palette.primary.b2}
            />
            <Typography
              variant={'body2'}
              color={lightTheme.palette.primary.b2}
              textAlign={'right'}
              marginLeft={1}
            >
              {'Télécharger la fiche'}
            </Typography>
          </Button>
        </div>

        <Container
          maxWidth={'lg'}
          sx={{ paddingTop: '25px', paddingBottom: '25px' }}
        >
          <Grid container spacing={4}>
            {/* Section fiche */}
            <Grid item xs={12} sm={6} lg={6} order={{ sm: 1, lg: 1 }}>
              <BannerCircle
                sx={{
                  display: { xs: 'none', lg: 'flex' },
                }}
              >
                <TitleBox style={{ color: '#fff' }}>Fiche </TitleBox>
              </BannerCircle>

              <ItemLong
                sx={{
                  height: { lg: 'calc(100% - 50px)' },
                }}
              >
                <ProfilContainer>
                  {/* <TitleContainer>
                    <TitleSection></TitleSection>
                    <IconContainer onClick={handleEditBesoin}>
                      <FontAwesomeIcon
                        icon={faPenCircle}
                        fontSize={40}
                        color={lightTheme.palette.primary.b2}
                      />
                    </IconContainer>
                  </TitleContainer> */}

                  <WrapperProfilText>
                    <Typography
                      sx={{
                        fontFamily: 'Century Gothic Bold',
                        fontSize: { xs: 20 },
                        lineHeight: '120%',
                        color: lightTheme.palette.text.primary,
                        textAlign: 'center',
                        display: { lg: 'block' },
                        textTransform: 'uppercase',
                      }}
                    >
                      {besoinEntreprise?.poste?.titre}
                    </Typography>
                    <Typography
                      variant={'subtitle2'}
                      textAlign={'center'}
                      textTransform={'capitalize'}
                    >
                      {STATUS_CONTRAT.find(
                        (item) => item.id === besoinEntreprise?.typeContrat
                      )?.titre || 'N/A'}
                    </Typography>
                    <Typography
                      variant={'subtitle2'}
                      color={lightTheme.palette.grey.grey}
                      textAlign={'center'}
                    >
                      {besoinEntreprise?.scope ? 'Scope' : 'Hors scope'}
                    </Typography>
                  </WrapperProfilText>
                  <BannerProfilContainer>
                    <WrapperBanner>
                      <BannerProfil>
                        <Typography>{t('box.jobExpectedStartDate')}</Typography>
                        <Typography>
                          <FontAwesomeIcon icon={faCalendarAlt} />{' '}
                          {moment(besoinEntreprise?.dateDebut)
                            .tz('Europe/Paris')
                            .format('DD/MM/YYYY')}
                        </Typography>
                      </BannerProfil>
                      <BannerProfil>
                        <Typography>{t('box.jobLocation')}</Typography>

                        <Typography
                          textAlign={'right'}
                          title={'snapShot.adresses'}
                          style={{
                            width: '50%',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            lineHeight: '16px',
                          }}
                        >
                          <FontAwesomeIcon icon={faMapMarker} />{' '}
                          {besoinEntreprise?.lieu}
                          {/*  / {besoinEntreprise?.pays} */}
                        </Typography>
                      </BannerProfil>
                      {/* <BannerProfil>
                        <FontAwesomeIcon icon={faHome} />
                        <Typography>snapShot.pays</Typography>
                      </BannerProfil> */}
                      <BannerProfil>
                        <Typography>Durée de la mission </Typography>
                        <Typography
                          textAlign={'right'}
                          title={'snapShot.email'}
                          style={{
                            width: '50%',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {besoinEntreprise?.dureeMission} mois
                        </Typography>
                      </BannerProfil>
                      <BannerProfil>
                        <Typography>Télétravail </Typography>
                        <Typography>
                          {' '}
                          {besoinEntreprise?.teletravailPropose}{' '}
                          {besoinEntreprise?.teletravailPropose > 1
                            ? 'jours'
                            : 'jour'}
                        </Typography>
                      </BannerProfil>
                      <BannerProfil>
                        <Typography>TJM </Typography>
                        <Typography>{besoinEntreprise?.tjm} €</Typography>
                      </BannerProfil>
                      <BannerProfil>
                        <Typography>
                          Nombre d'années d'expérience(s) requis{' '}
                        </Typography>
                        <Typography>
                          {besoinEntreprise?.nbrAnneeExperience}
                        </Typography>
                      </BannerProfil>
                      {besoinEntreprise?.priorite && (
                        <BannerProfil>
                          <Typography>Priorité </Typography>
                          <Typography
                            color={
                              besoinEntreprise.priorite === '3'
                                ? lightTheme.palette.error.dark
                                : besoinEntreprise.priorite === '2'
                                ? lightTheme.palette.warning.dark
                                : lightTheme.palette.grey.grey
                            }
                          >
                            {
                              prioriteEntrepriselList.find(
                                (item) => item.id === besoinEntreprise.priorite
                              ).titre
                            }
                          </Typography>
                        </BannerProfil>
                      )}
                      {besoinEntreprise?.originLead && (
                        <BannerProfil>
                          <Typography>Origine du Recrutement</Typography>
                          <Typography>
                            {
                              originLeadList[besoinEntreprise.originLead - 1]
                                .titre
                            }
                          </Typography>
                        </BannerProfil>
                      )}
                      {besoinEntreprise?.source && (
                        <BannerProfil>
                          <Typography>Besoin</Typography>
                          <Typography>{besoinEntreprise?.source}</Typography>
                        </BannerProfil>
                      )}
                      {/*  {besoinEntreprise?.source2 && (
                        <BannerProfil>
                          <Typography>Besoin</Typography>
                          <Typography>{besoinEntreprise?.source2}</Typography>
                        </BannerProfil>
                      )} */}
                    </WrapperBanner>
                  </BannerProfilContainer>
                </ProfilContainer>
              </ItemLong>
            </Grid>

            <Grid item xs={12} sm={6} order={{ sm: 2 }}>
              <BannerCircle
                sx={{
                  display: { xs: 'none', lg: 'flex' },
                }}
              >
                <TitleBox style={{ color: '#fff' }}>
                  {t('box.jobDescription')}
                </TitleBox>
              </BannerCircle>
              <ItemLong
                sx={{
                  height: { lg: 'calc(100% - 50px)' },
                }}
              >
                <Box>
                  {besoinEntreprise?.descriptifPoste}
                  {/*  {seeMore
                    ? besoinEntreprise?.descriptifPoste
                    : truncate(besoinEntreprise?.descriptifPoste)} */}
                  {/* {besoinEntreprise?.descriptifPoste.length > 150 && (
                    <a
                      style={{
                        cursor: 'pointer',
                        color: '#96A0FF',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                      onClick={handleSeeMore}
                    >
                      {seeMore ? 'Voir moin' : 'Voir plus'}
                    </a>
                  )} */}
                </Box>
              </ItemLong>
            </Grid>

            <Grid item xs={12} sm={12} order={{ sm: 3 }}>
              <ItemLong>
                <TitleContainer>
                  <TitleBox>Environnements Techniques</TitleBox>
                </TitleContainer>
                <Box>
                  <ul className="list-values">
                    {besoinEntreprise?.environnementsTechniques &&
                      besoinEntreprise?.environnementsTechniques.map(
                        (environnementTechnique, i) => (
                          <li>{environnementTechnique}</li>
                        )
                      )}
                  </ul>
                </Box>
              </ItemLong>
            </Grid>

            <Grid item xs={12} sm={12} order={{ sm: 4 }}>
              <ItemLong>
                <TitleContainer>
                  <TitleBox>Compétences obligatoires</TitleBox>
                </TitleContainer>
                <Box>
                  <ul className="list-values">
                    {besoinEntreprise?.competences &&
                      besoinEntreprise?.competences.map((competence, i) => (
                        <li>{competence}</li>
                      ))}
                  </ul>
                </Box>
              </ItemLong>
            </Grid>

            <Grid item xs={12} sm={12} order={{ sm: 5 }}>
              <ItemLong>
                <TitleContainer>
                  <TitleBox>Softskills</TitleBox>
                </TitleContainer>
                <Box>
                  <ul className="list-values">
                    {besoinEntreprise?.softskils &&
                      besoinEntreprise?.softskils.map((softskil, i) => (
                        <li>{softskil}</li>
                      ))}
                  </ul>
                </Box>
              </ItemLong>
            </Grid>

            <Grid item xs={12} sm={12} order={{ sm: 6 }}>
              <ItemLong>
                <TitleContainer sx={{ marginBottom: 5 }}>
                  <TitleBox>
                    Listes des candidats matcher avec le besoin
                  </TitleBox>
                  <WrapperButton sx={{ columnGap: '15px' }}>
                    <SearchInputBasic onChange={handleSearchChange} />
                  </WrapperButton>
                </TitleContainer>
                <Box>
                  <ListTable
                    type={'candidat'}
                    items={candidates}
                    setId={setId}
                    loading={loading}
                    handleClick={handleClickToProfil}
                    handleRemove={handleRemoveProfil}
                    listeMatching={true}
                    matchingPost={besoinEntreprise?.poste?.titre}
                    isAdmin={true}
                  />
                </Box>
              </ItemLong>
            </Grid>

            <Grid item xs={12} sm={12} order={{ sm: 6 }}>
              <ItemLong>
                <TitleContainer sx={{ marginBottom: 5 }}>
                  <TitleBox>Listes des candidats</TitleBox>
                  {/* <WrapperButton sx={{ columnGap: '15px' }}>
                    <SearchInputBasic onChange={handleSearchChange} />
                  </WrapperButton> */}
                  {['DI', 'DC', 'PP', 'PC'].includes(besoinEntreprise?.typeContrat) && (
                    <WrapperIcons>
                      <IconContainer onClick={handleOpen}>
                        <FontAwesomeIcon
                          icon={faPenCircle}
                          fontSize={50}
                          color={lightTheme.palette.primary.b2}
                        />
                      </IconContainer>
                    </WrapperIcons>
                  )}
                </TitleContainer>
                <Box>
                  <ListTable
                    type={'candidatSelectionner'}
                    items={candidatSelectionner}
                    setId={setId}
                    loading={loading}
                    handleClick={handleClickToProfil}
                    handleRemove={handleRemoveProfil}
                    listeMatching={false}
                    matchingPost={besoinEntreprise?.poste?.titre}
                    isAdmin={true}
                  />
                </Box>
              </ItemLong>
            </Grid>

            {['CL', 'CS', 'MD'].includes(besoinEntreprise?.typeContrat) && (
              <Grid item xs={12} sm={12} order={{ sm: 6 }}>
                <ItemLong>
                  <TitleContainer sx={{ marginBottom: 5 }}>
                    <TitleBox>Listes des candidats retenus</TitleBox>
                    {/* <WrapperButton sx={{ columnGap: '15px' }}>
                      <SearchInputBasic onChange={handleSearchChange} />
                    </WrapperButton> */}
                    {['CL', 'CS'].includes(besoinEntreprise?.typeContrat) && (
                      <WrapperIcons>
                        <IconContainer onClick={handleOpen}>
                          <FontAwesomeIcon
                            icon={faPenCircle}
                            fontSize={50}
                            color={lightTheme.palette.primary.b2}
                          />
                        </IconContainer>
                      </WrapperIcons>
                    )}
                  </TitleContainer>
                  <Box>
                    <ListTable
                      type={'candidatRetenu'}
                      items={candidatRetenu}
                      setId={setId}
                      loading={loading}
                      handleClick={handleClickToProfil}
                      handleRemove={handleRemoveProfil}
                      listeMatching={false}
                      matchingPost={besoinEntreprise?.poste?.titre}
                      isAdmin={true}
                    />
                  </Box>
                </ItemLong>
              </Grid>
            )}
          </Grid>
        </Container>
        {/*</>
        ) : (
          <CandidateProfil id={id} />
        )}*/}
      </div>

      {open && (
        <CvthequeModal
          open={open}
          onClose={handleClose}
          besoin={besoinEntreprise}
          onSave={handleSaveBesoin}
        />
      )}

      {openDetailCandidat && (
        <DetailCandidat
          open={openDetailCandidat}
          onClose={handleCloseDetailCandidat}
          candidat={candidat}
          companyId={besoinEntreprise?.entreprise?.id}
          poste={besoinEntreprise?.poste?.titre}
          isAdmin={true}
        />
      )}

      {openBesoin && (
        <EditBesoinEntreprise
          open={openBesoin}
          onClose={handleCloseBesoin}
          onSave={handleSaveBesoin}
          id={besoinEntreprise?.entreprise.id}
          idContact={besoinEntreprise?.contact?.id}
          besoin={besoinEntreprise}
        />
      )}

      {openDownloadBesoin && (
        <DownloadBesoinEntreprise
          open={openDownloadBesoin}
          onClose={handleCloseBesoin}
          besoin={besoinEntreprise}
        />
      )}
    </>
  )
}

export default MatchingCandidate
