import React, { useCallback, useEffect, useState } from 'react'
import { MasonryLayout } from '../../../../../components/layouts'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  styled,
  Typography,
} from '@mui/material'
import { CustomButton } from '../../../../../components/buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faPhone,
  faCircleExclamation,
  faCircleCheck,
  faArrowLeft,
  faHome,
  faPenCircle,
  faMale,
  faFemale,
  faBriefcase,
  faCalculatorSimple,
} from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../../../../styles/theme/lightTheme'
import { GedTable, OffreTable } from '../../../../../components/tables'
import Api from '../../../../../services/api.service'
import { handlingErrors } from '../../../../../utils'
import { Slide, ToastContainer, toast } from 'react-toastify'
import '../../../../../styles/components/_box.scss'
import BiBlockTable from '../../../../../components/tables/BiBlockTable'
import moment from 'moment/moment'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ChoiceOfferModal,
  EditDocuments,
  EditInfos,
  DeleteGeneralModal,
  EditList,
  EditBesoin,
  EditEvent,
} from '../../../../../components/modals'

import DetailOfferModal from '../../../../../components/modals/DetailOfferModal'
import { useForm } from 'react-hook-form'
import {
  SOFTSKILLS,
  VALEURS,
  canalList,
  disponibilitesList2,
  disponibleSousList2,
  environnementsTechniques,
} from '../../../../../data'
import EditResume from '../../../../../components/modals/EditResume'
import EditCommentaire from '../../../../../components/modals/EditCommentaire'
import GedTabs from '../../../../../components/tables/GedTabs'
import CalculatorModal from '../../../../../components/modals/CalculatorModal'
import AddCra from '../../../../../components/modals/AddCra'
import AddConge from '../../../../../components/modals/AddConge'

const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const TitleSection = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,
  textTransform: 'uppercase',
}))

const IconContainer = styled(Box)(({ theme }) => ({
  width: 80,
  height: 30,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  '&:hover': {
    borderWidth: '2px',
  },

  '&:active': {
    borderWidth: '1px',
  },
}))

const ProfilContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '45px',
  justifyContent: 'space-between',
}))

const WrapperProfilText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  rowGap: '5px',
}))

const BannerProfil = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 40,
  backgroundColor: lightTheme.palette.primary.b6,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
}))

const WrapperBanner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '10px',
}))

const BannerProfilContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '10px',
}))

const ProspectCandidateProfil = () => {
  const [openEvent, setOpenEvent] = useState(false)
  const [open, setOpen] = useState(false)
  const [openDoc, setOpenDoc] = useState(false)
  const [openBesoin, setOpenBesoin] = useState(false)
  const [openCommentaire, setOpenCommentaire] = useState(false)
  const [openResume, setOpenResume] = useState(false)
  const [openValeurs, setOpenValeurs] = useState(false)
  const [openCompetences, setOpenCompetences] = useState(false)
  const [openCompetencesAnnexe, setOpenCompetencesAnnexe] = useState(false)
  const [openSoftskils, setOpenSoftskils] = useState(false)
  const [loading, setLoading] = useState(true)
  const [snapShot, setSnapShot] = useState(null)
  const [loadingTest, setLoadingTest] = useState(false)
  const [loadingInscription, setLoadingInscription] = useState(false)
  const [besoin, setBesoin] = useState([])
  const [resume, setResume] = useState([])
  const [commentaire, setCommentaire] = useState([])
  const [dateRdv, setDateRdv] = useState([])
  const [dateRelation, setDateRelation] = useState([])
  const [isFile, setIsFile] = useState([])
  const [role, setRole] = useState('')
  const [event, setEvent] = useState({})
  const [openAddCra, setOpenAddCra] = useState(false)
  const [openAddConge, setOpenAddConge] = useState(false)

  let navigate = useNavigate()
  const params = useParams()

  const handleCloseAddCra = useCallback(() => {
    setOpenAddCra(false)
    //fetchData()
  }, [])

  const handleCloseConge = useCallback(() => {
    setOpenAddConge(false)
    //fetchData()
  }, [])

  const handleOpenEvent = (event) => {
    setEvent(event)
    setOpenEvent(true)
  }
  const handleCloseEvent = () => {
    setOpenEvent(false)
    fetchData()
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    fetchData()
  }
  const handleOpenDoc = () => setOpenDoc(true)
  const handleCloseDoc = () => {
    setOpenDoc(false)
    fetchData()
  }
  const handleOpenCommentaire = () => setOpenCommentaire(true)
  const handleCloseCommentaire = () => {
    setOpenCommentaire(false)
    fetchData()
  }
  const handleOpenBesoin = () => setOpenBesoin(true)
  const handleCloseBesoin = () => {
    setOpenBesoin(false)
    fetchData()
  }
  const handleOpenResume = useCallback(() => setOpenResume(true), [])
  const handleCloseResume = useCallback(() => {
    setOpenResume(false)
    //fetchData()
  }, [])
  const handleOpenCompetences = () => setOpenCompetences(true)
  const handleCloseCompetences = () => {
    setOpenCompetences(false)
    fetchData()
  }
  const handleOpenCompetencesAnnexe = () => setOpenCompetencesAnnexe(true)
  const handleCloseCompetencesAnnexe = () => {
    setOpenCompetencesAnnexe(false)
    fetchData()
  }
  const handleOpenValeurs = () => setOpenValeurs(true)
  const handleCloseValeurs = () => {
    setOpenValeurs(false)
    fetchData()
  }
  const handleOpenSoftskils = () => setOpenSoftskils(true)
  const handleCloseSoftskils = () => {
    setOpenSoftskils(false)
    fetchData()
  }

  const handleOpenOffer = () => setOpenOffre(true)
  const handleCloseOffer = () => {
    setOpenOffre(false)
    //fetchData()
  }
  const handleSave = () => {
    fetchData()
    setOpen(false)
    setOpenDoc(false)
    setOpenBesoin(false)
    setOpenResume(false)
    setOpenCommentaire(false)
    setOpenCompetences(false)
    setOpenCompetencesAnnexe(false)
    setOpenSoftskils(false)
    setOpenValeurs(false)
    setOpenOffre(false)
    setOpenAddConge(false)
  }

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const handleCloseDeleteModal = () => setOpenDeleteModal(false)
  const handleConfirmDeleteModal = () => removeFile()
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [fileDelete, setFileDelete] = useState()

  const [openOffre, setOpenOffre] = useState(false)
  const [offres, setOffres] = useState([])
  const [offreSelect, setOffreSelect] = useState('')
  const [offresSelect, setOffresSelect] = useState([])
  const [loadingOffer, setLoadingOffer] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)
  const { handleSubmit } = useForm({ mode: 'onBlur' })

  const [loadingCalculator, setLoadingCalculator] = useState(false)
  const [openCalculator, setOpenCalculator] = useState(false)
  const handleCloseCalculatorModal = () => setOpenCalculator(false)
  const handleConfirmCalculatorModal = () => setOpenCalculator(false)

  const [openAction, setOpenAction] = useState(false)
  const [action, setAction] = useState()
  const [openDeleteActionModal, setOpenDeleteActionModal] = useState(false)
  const [openDetailAction, setOpenDetailAction] = useState(false)
  const handleCloseDeleteActionModal = () => setOpenDeleteActionModal(false)
  const handleConfirmDeleteActionModal = () => removeAction()

  const handleEditAction = (action) => {
    setAction(action)
    setOpenAction(true)
  }
  const handleDetailAction = (action) => {
    setAction(action)
    setOpenDetailAction(true)
  }
  const handleRemoveAction = (action) => {
    setAction(action)
    setOpenDeleteActionModal(true)
  }
  const handleCloseAction = () => {
    setOpenDetailAction(false)
    setAction()
  }
  const handleCloseEditAction = () => {
    setOpenAction(false)
    //setAction()
  }

  const saveTjm = async (tjmVendu, tjrmPoucentage, salaire) => {
    setLoading(true)

    try {
      const result = await Api.candidate.edit(snapShot?.candidat?.id, {
        tjmCible: tjmVendu,
        marge: parseInt(tjrmPoucentage),
        salaire: parseInt(salaire),
      })
      const handling = handlingErrors(result)

      if (handling.ok) {
        fetchData()
      }
      setLoading(false)
      handleCloseCalculatorModal()
    } catch (error) {
      setLoading(false)
      handleCloseCalculatorModal()
      console.log(error)
    }
  }

  const removeAction = async () => {
    setLoadingDelete(true)
    try {
      let result = await Api.action.delete(action)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        fetchData()
        setOpenDeleteActionModal(false)
      } else {
        window.scrollTo(0, 0)
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingDelete(false)
  }

  const saveEvent = () => {
    role === 'ROLE_ENTREPRISE'
      ? navigate(`/admin/gestion-entreprise/profil-entreprise/${params.userId}`)
      : navigate(
          `/admin/gestion-entreprise/profil-client-candidat/${params.userId}`
        )
  }

  useEffect(() => {
    if (params.userId) {
      fetchData()
    }
  }, [params.userId])

  const fetchData = async () => {
    setLoading(true)
    getMatchingBesoin()
    try {
      let result = await Api.user.detail(params.userId)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        const res = handling.data
        setSnapShot(res)
        setLoading(false)
        setDateRdv(res.candidat?.calendriers.sort((a, b) => a.id - b.id))
        setBesoin(res.candidat?.besoin)
        setResume(res.candidat?.resume)
        setCommentaire(res.commentaire)
        setRole(res.role)
        let userOffres = []
        if (res.userOffres) {
          res.userOffres.map((userOffre, i) => {
            userOffres.push({
              id: userOffre.id,
              offre: `/api/offres/${userOffre.offre.id}`,
              prix: userOffre.prix,
            })
          })
        }
        setOffresSelect(res.actionsCandidat)
        let docs = []
        //if (res.candidat?.cv) docs.push({ type: 'cv', nom: res.candidat?.cv })
        if (res.documents) {
          res.documents.map((doc, i) => {
            docs.push(doc)
          })
        }

        setIsFile(docs)
      } else {
        setLoading(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
  }

  useEffect(() => {
    getOffers()
  }, [])
  const getOffers = async () => {
    try {
      let result = await Api.offer.listWithFilter({
        categorie: 'candidat',
      })

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoadingOffer(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoadingOffer(false)
        setOffres(res)
      } else {
        setLoadingOffer(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoadingOffer(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  const sendEvaluation = async () => {
    setLoadingTest(true)

    try {
      const result = await Api.candidate.sendEval(params.userId, {
        url: 'https://www.youtube.com/channel/UC8YZ2pn8zIzx_GnJw_F6fdQ',
      })
      const handling = handlingErrors(result)

      if (handling.ok) {
        toast.success('Le test de personnalité a été envoyé avec succès !', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
      }
      setLoadingTest(false)
    } catch (error) {
      setLoadingTest(false)
    }
  }

  const sendInscriptionPropos = async () => {
    setLoadingInscription(true)

    try {
      const result = await Api.user.edit(params.userId, {
        status: 'proposition_inscription',
      })
      const handling = handlingErrors(result)

      if (handling.ok) {
        toast.success('La proposition a été envoyé avec succès !', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
      }
      setLoadingInscription(false)
    } catch (error) {
      setLoadingInscription(false)
    }
  }

  const handleRemove = (idFile) => {
    setOpenDeleteModal(true)
    setFileDelete(idFile)
  }

  const removeFile = async () => {
    setLoadingDelete(true)
    try {
      let result = await Api.document.delete(fileDelete)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        const indexFile = isFile.findIndex((file) => file.id === fileDelete)

        if (indexFile > -1) {
          isFile.splice(indexFile, 1)
        }
        setIsFile(isFile)
        setOpenDeleteModal(false)
      } else {
        window.scrollTo(0, 0)
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingDelete(false)
  }

  const [search, setSearch] = useState('')
  const [loadingMatching, setLoadingMatching] = useState(false)
  const [besoinEntreprises, setBesoinEntreprises] = useState([])
  const [openDetailBesoin, setOpenDetailBesoin] = useState(false)
  const [countBesoin, setCountBesoin] = useState(0)
  const [pageBesoin, setPageBesoin] = useState(1)

  const getMatchingBesoin = async () => {
    let payload = {
      search: search,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    setLoadingMatching(true)

    try {
      const result = await Api.besoinEntreprise.matchingoffresByCandidat(
        params.userId
      )

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setBesoinEntreprises(res.data.besoins)
        //console.log(res.data.candidat)
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingMatching(false)
  }

  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <div style={{ width: '100%' }}>
      <Button
        sx={{
          margin: '10px',
        }}
        onClick={() => navigate(-1)}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          fontSize={15}
          color={lightTheme.palette.primary.b2}
        />{' '}
        <Typography
          variant={'body2'}
          color={lightTheme.palette.primary.b2}
          textAlign={'right'}
          marginLeft={1}
        >
          {'Retour'}
        </Typography>
      </Button>
      <MasonryLayout
        type={'candidat'}
        handleOpenDoc={handleOpenDoc}
        handleOpenBesoin={handleOpenBesoin}
        handleOpenResume={handleOpenResume}
        handleOpenCommentaire={handleOpenCommentaire}
        handleOpenValeurs={handleOpenValeurs}
        handleOpenCompetences={handleOpenCompetences}
        handleOpenCompetencesAnnexe={handleOpenCompetencesAnnexe}
        handleOpenSoftskils={handleOpenSoftskils}
        handleOpenOffer={handleOpenOffer}
        profil={
          <ProfilContainer>
            <TitleContainer>
              <TitleSection></TitleSection>
              <IconContainer onClick={handleOpen}>
                <FontAwesomeIcon
                  icon={faPenCircle}
                  fontSize={40}
                  color={lightTheme.palette.primary.b2}
                />
              </IconContainer>
            </TitleContainer>
            <WrapperProfilText>
              <Typography
                variant={'subtitle2'}
                textAlign={'center'}
                textTransform={'capitalize'}
                sx={{ fontSize: '22px!important' }}
              >
                {snapShot.prenom} {snapShot.nom}
              </Typography>
              {snapShot.candidat?.poste && (
                <Typography
                  variant={'subtitle2'}
                  textAlign={'center'}
                  sx={{ fontSize: '20px!important' }}
                >
                  {snapShot.candidat.poste.titre}
                </Typography>
              )}
              {snapShot.candidat?.typeProfil && (
                <Typography variant={'subtitle2'} textAlign={'center'}>
                  {snapShot.candidat?.typeProfil}
                </Typography>
              )}

              {snapShot.candidat?.salaire && (
                <>
                  <Typography variant={'body2'} textAlign={'center'}>
                    {snapShot?.candidat?.typeProfil ===
                      'Candidat Partenaire ESN' ||
                    snapShot?.candidat?.typeProfil === 'Indépendant'
                      ? 'TJM'
                      : 'Salaire annuel'}{' '}
                    : {snapShot.candidat?.salaire} €<br />
                    {snapShot.candidat?.tjmCible && (
                      <>
                        TJM Cible : {Math.round(snapShot.candidat?.tjmCible)} €<br />
                      </>
                    )}
                    <Button
                      variant="outlined"
                      startIcon={<FontAwesomeIcon icon={faCalculatorSimple} />}
                      size="small"
                      sx={{ width: 200 }}
                      onClick={() => setOpenCalculator(true)}
                    >
                      Calcul TJM
                    </Button>
                  </Typography>
                </>
              )}

              {snapShot.candidat?.experience !== null && (
                <Typography
                  variant={'body2'}
                  color={lightTheme.palette.grey.grey}
                  textAlign={'center'}
                >
                  {snapShot.candidat.experience === 0
                    ? 'Aucune expérience'
                    : snapShot.candidat.experience +
                      ' ' +
                      (snapShot.candidat.experience > 1
                        ? "ans d'expérience"
                        : "an d'expérience")}{' '}
                </Typography>
              )}
              {snapShot.candidat?.experiences && (
                <Typography
                  variant={'body2'}
                  color={lightTheme.palette.grey.grey}
                  textAlign={'center'}
                >
                  {snapShot.candidat.experiences}
                </Typography>
              )}
              {snapShot.candidat?.typeDisponibilite && (
                <Typography
                  variant={'body2'}
                  color={lightTheme.palette.grey.grey}
                  textAlign={'center'}
                >
                  {
                    disponibilitesList2.find(
                      (dispo) =>
                        dispo.id == snapShot.candidat?.typeDisponibilite
                    ).titre['fr']
                  }

                  {snapShot.candidat?.typeDisponibilite == 2 &&
                    snapShot.candidat?.disponibleSous &&
                    disponibleSousList2.find(
                      (dispoS) => dispoS.id == snapShot.candidat?.disponibleSous
                    ).titre['fr']}

                  {snapShot.candidat?.typeDisponibilite == 4 &&
                    moment(snapShot.candidat?.disponible)
                      .tz('Europe/Paris')
                      .format('DD/MM/YYYY')}
                </Typography>
              )}
            </WrapperProfilText>
            <BannerProfilContainer>
              <WrapperBanner>
                {/* {snapShot.langues?.length > 0 && (
                  <BannerProfil>
                    <FontAwesomeIcon icon={faComment} />
                    <Typography>{snapShot.langues}</Typography>
                  </BannerProfil>
                )} */}

                <BannerProfil>
                  <FontAwesomeIcon icon={faHome} />
                  <Typography>
                    {snapShot.ville} / {snapShot.pays}
                  </Typography>
                </BannerProfil>
                <BannerProfil>
                  <FontAwesomeIcon icon={faEnvelope} />
                  <Typography
                    textAlign={'right'}
                    title={snapShot.email}
                    style={{
                      width: '90%',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {snapShot.email}
                  </Typography>
                </BannerProfil>
                <BannerProfil>
                  <FontAwesomeIcon icon={faPhone} />
                  <Typography>{snapShot.telephone}</Typography>
                </BannerProfil>
                {/* {loadingTest ? (
                  <CircularProgress />
                ) : (
                  <CustomButton
                    displayIcon={'none'}
                    handleClick={sendEvaluation}
                  >
                    Test de personnalité
                  </CustomButton>
                )} */}
                {/* {loadingInscription ? (
                  <CircularProgress />
                ) : (
                  <CustomButton
                    displayIcon={'none'}
                    handleClick={sendInscriptionPropos}
                  >
                    Proposer l'inscription
                  </CustomButton>
                )} */}

                {snapShot.candidat?.canalSourcing && (
                  <BannerProfil>
                    <Typography>Origine du Recrutement </Typography>
                    <Typography>
                      {canalList[snapShot.candidat?.canalSourcing - 1].titre}
                    </Typography>
                  </BannerProfil>
                )}
              </WrapperBanner>
              {/*<BorderButtonPurple>Voir le résumé</BorderButtonPurple>*/}
              {snapShot?.candidat?.typeProfil === 'Candidat Partenaire ESN' && (
                <>
                  <Divider>
                    <Chip label="Contact Commercial ESN" size="small" />
                  </Divider>

                  <BannerProfil>
                    <FontAwesomeIcon
                      icon={
                        snapShot.candidat?.genreCommercial === 'Madame'
                          ? faFemale
                          : faMale
                      }
                    />
                    <Typography>
                      {snapShot.candidat?.prenomCommercial}{' '}
                      {snapShot.candidat?.nomCommercial}
                    </Typography>
                  </BannerProfil>
                  <BannerProfil>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <Typography
                      textAlign={'right'}
                      title={snapShot.candidat?.emailCommercial}
                      style={{
                        width: '90%',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {snapShot.candidat?.emailCommercial}
                    </Typography>
                  </BannerProfil>
                  <BannerProfil>
                    <FontAwesomeIcon icon={faPhone} />
                    <Typography>
                      {snapShot.candidat?.telephoneCommercial}
                    </Typography>
                  </BannerProfil>
                  <BannerProfil>
                    <FontAwesomeIcon icon={faBriefcase} />
                    <Typography>
                      {snapShot.candidat?.societeCommercial}
                    </Typography>
                  </BannerProfil>
                </>
              )}
              {snapShot.status === 'active' && (
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <CustomButton
                      displayIcon={'none'}
                      handleClick={() => setOpenAddCra(true)}
                    >
                      Compte rendu d'activité
                    </CustomButton>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomButton
                      displayIcon={'none'}
                      handleClick={() => setOpenAddConge(true)}
                    >
                      Mes congés
                    </CustomButton>
                  </Grid>
                </Grid>
              )}
            </BannerProfilContainer>
          </ProfilContainer>
        }
        besoinClient={
          <Typography variant={'body2'} color={lightTheme.palette.grey.grey}>
            {besoin}
          </Typography>
        }
        resumeClient={
          <Typography variant={'body2'} color={lightTheme.palette.grey.grey}>
            {resume}
          </Typography>
        }
        commentaireAdmin={
          <Typography variant={'body2'} color={lightTheme.palette.grey.grey}>
            {commentaire}
          </Typography>
        }
        rendezVous={
          <BiBlockTable
            rdvAVenir={dateRdv}
            heureAVenir={''}
            handleOpenEvent={handleOpenEvent}
            type={'candidat'}
          />
        }
        relation={<BiBlockTable rdvAVenir={dateRelation} heureAVenir={''} />}
        feedback={dateRdv}
        offre={
          snapShot.actionsCandidat && (
            <OffreTable
              handleRemoveAction={handleRemoveAction}
              handleEditAction={handleEditAction}
              handleDetailAction={handleDetailAction}
              type={'candidat'}
              offres={snapShot.actionsCandidat.sort((a, b) => b.id - a.id)}
              dateOffer={moment(snapShot.date)
                .tz('Europe/Paris')
                .format('DD/MM/YYYY H:mm')}
            />
          )
        }
        ged={
          isFile ? (
            <GedTabs
              files={isFile}
              dateFile={moment(snapShot.date)
                .tz('Europe/Paris')
                .format('DD/MM/YYYY H:mm')}
              handleRemove={handleRemove}
            />
          ) : (
            <GedTabs />
          )
        }
        displayParams={true}
        displayCompetencesSection={'display'}
        competences={snapShot.candidat.competences}
        competencesAnnexe={snapShot.candidat.competencesAnnexe}
        displaySoftskilSection={'display'}
        softskils={snapShot.candidat.softskils}
        displayValeurSection={'display'}
        environnementsTechniques={snapShot.candidat.environnementsTechniques}
        besoinsEntreprise={besoinEntreprises}
        loadingBesoin={loadingMatching}
      />

      {open && (
        <EditInfos
          open={open}
          onClose={handleClose}
          onSave={handleSave}
          id={params.userId}
          role={'ROLE_ADMIN'}
          type={role}
        />
      )}

      {openCommentaire && (
        <EditCommentaire
          open={openCommentaire}
          onClose={handleCloseCommentaire}
          onSave={handleSave}
          id={params.userId}
        />
      )}

      {openResume && (
        <EditResume
          open={openResume}
          onClose={handleCloseResume}
          onSave={handleSave}
          id={params.userId}
        />
      )}

      {openBesoin && (
        <EditBesoin
          open={openBesoin}
          onClose={handleCloseBesoin}
          onSave={handleSave}
          id={params.userId}
        />
      )}

      {openDoc && (
        <EditDocuments
          open={openDoc}
          onClose={handleCloseDoc}
          onSave={handleSave}
          id={params.userId}
          role={'ROLE_CANDIDAT'}
        />
      )}

      {openCompetences && (
        <EditList
          open={openCompetences}
          onClose={handleCloseCompetences}
          id={snapShot.candidat.id}
          type={'competences'}
          onSave={handleSave}
          values={snapShot.candidat.competences}
          datas={[]}
        />
      )}

      {openCompetencesAnnexe && (
        <EditList
          open={openCompetencesAnnexe}
          onClose={handleCloseCompetencesAnnexe}
          id={snapShot.candidat.id}
          type={'competencesAnnexe'}
          onSave={handleSave}
          values={snapShot.candidat.competencesAnnexe}
          datas={[]}
        />
      )}

      {openSoftskils && (
        <EditList
          open={openSoftskils}
          onClose={handleCloseSoftskils}
          id={snapShot.candidat.id}
          type={'softskils'}
          onSave={handleSave}
          values={snapShot.candidat.softskils}
          datas={[...SOFTSKILLS['fr']].sort()}
        />
      )}

      {openValeurs && (
        <EditList
          open={openValeurs}
          onClose={handleCloseValeurs}
          id={snapShot.candidat.id}
          type={'Environnements Techniques'}
          onSave={handleSave}
          values={snapShot.candidat.environnementsTechniques}
          datas={[environnementsTechniques]}
        />
      )}

      {openOffre && (
        <ChoiceOfferModal
          open={openOffre}
          onClose={handleCloseOffer}
          onSave={handleSave}
          /* items={offres} */
          /* handleClick={handleSubmit()} */
          /* value={offreSelect} */
          candidat={snapShot}
          loading={loadingButton}
          type={'candidat'}
        />
      )}

      {openDeleteModal && (
        <DeleteGeneralModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          onConfirm={handleConfirmDeleteModal}
          loading={loadingDelete}
        />
      )}

      {openCalculator && (
        <CalculatorModal
          open={openCalculator}
          onClose={handleCloseCalculatorModal}
          onConfirm={saveTjm}
          loading={loadingCalculator}
          candidat={snapShot}
        />
      )}

      {openAddCra && (
        <AddCra open={openAddCra} onClose={handleCloseAddCra} user={snapShot} />
      )}

      {openAddConge && (
        <AddConge
          open={openAddConge}
          onClose={handleCloseConge}
          user={snapShot}
          onRefresh={handleSave}
        />
      )}

      {openEvent && (
        <EditEvent
          open={openEvent}
          onClose={handleCloseEvent}
          id={params.userId}
          //role={role}
          event={event}
          onSave={handleCloseEvent}
          showParams={true}
        />
      )}

      {openOffre && (
        <ChoiceOfferModal
          open={openOffre}
          onClose={handleCloseOffer}
          onSave={handleSave}
          /* items={offres} */
          /* handleClick={handleSubmit()} */
          /* value={offreSelect} */
          candidat={snapShot}
          loading={loadingButton}
          type={'candidat'}
        />
      )}

      {openAction && (
        <ChoiceOfferModal
          open={openAction}
          onClose={handleCloseEditAction}
          onSave={handleSave}
          /* items={offres} */
          /* handleClick={handleSubmit()} */
          actionValue={action}
          candidat={snapShot}
          loading={loadingButton}
          type={'candidat'}
        />
      )}

      {openDetailAction && (
        <DetailOfferModal
          open={openDetailAction}
          onClose={handleCloseAction}
          /* items={offres} */
          /* handleClick={handleSubmit()} */
          action={action}
          candidat={snapShot}
          loading={loadingButton}
          type={'action'}
          handleEdit={handleEditAction}
        />
      )}

      {openDeleteActionModal && (
        <DeleteGeneralModal
          open={openDeleteActionModal}
          onClose={handleCloseDeleteActionModal}
          onConfirm={handleConfirmDeleteActionModal}
          loading={loadingDelete}
        />
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </div>
  )
}

export default ProspectCandidateProfil
