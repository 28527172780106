import React, { useState } from 'react'
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  Modal,
  styled,
  Typography,
} from '@mui/material'
import { BorderButtonPurple, CustomButton, SwitchButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import { ButtonFilesUpload } from '../inputs'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { userLogout } from '../../store/slices/userAuthReducer'
import { useTranslation } from 'react-i18next'

const InputContainer = styled(FormControl)(({ theme }) => ({
  rowGap: '5px',
}))

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const proSchema = yup.object({})

const parSchema = yup.object({})

const EditDocuments = ({
  open,
  onClose,
  onSave,
  openDelete,
  id,
  role,
  isAdmin = true,
}) => {
  const currentUser = useSelector((state) => state.userAuth.user)
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [errorTypeFiles, setErrorTypeFiles] = useState(false)
  const [errorSizeFiles, setErrorSizeFiles] = useState(false)
  const dispatch = useDispatch()
  const [fileList, setFileList] = useState()
  const [fileListType, setFileListType] = useState([])
  const [isCheckedOnlyAdmin, setIsCheckedOnlyAdmin] = useState(false)
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(role === 'ROLE_ENTREPRISE' ? proSchema : parSchema),
  })

  const MAX_FILE_SIZE = 2 * 1024 * 1024 // 2 MB in bytes
  const switchToggleOnlyAdmin = () => {
    setIsCheckedOnlyAdmin(!isCheckedOnlyAdmin)
  }

  const validateSelectedFile = (selectedFile) => {
    if (!selectedFile) {
      return
    }

    const fileSizeKiloBytes = selectedFile.size

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      return false
    }
    return true
  }

  const handleChangeType = (event, documentKey, file) => {
    if (validateSelectedFile(file)) {
      fileListType[documentKey] = event.target.value
      setFileListType(fileListType)
    }
  }

  const handleFilesChange = (event) => {
    /*     setFileList(event.target.files)
    setValue('file', event.target.files) */

    const files = event.target.files // Get the selected files

    const validFiles = Array.from(files).filter(
      (file) => file.size <= MAX_FILE_SIZE
    )

    if (validFiles.length < files.length) {
      setErrorSizeFiles(true)

      setTimeout(function () {
        setErrorSizeFiles(false)
      }, 3000)
      /*  alert(
        `Certains fichiers dépassent la limite autorisée (20MB) et ne seront pas inclus. Veuillez sélectionner des fichiers plus petits`
      ) */
      //event.target.files = new FileList(validFiles, event.target.files.name) // Update the file input's FileList
    }

    setFileList(validFiles)
    setValue('file', validFiles)

    if (role === 'ROLE_ENTREPRISE') {
      setFileListType(
        Array.from({ length: validFiles.length }, (_, i) => 'document')
      )
    } else {
      setFileListType(Array.from({ length: validFiles.length }, (_, i) => 'cv'))
    }
  }

  const onSubmit = async (data) => {
    if (!fileList || fileList.length == 0) return
    setLoading(true)
    //return

    const {
      admin,
      candidat,
      date,
      entreprise,
      lang,
      offre,
      parent,
      password,
      status,
      ...rest
    } = data

    const formData = new FormData()
    formData.append('user', id)
    formData.append('par', currentUser.data.id)
    formData.append('onlyAdmin', isCheckedOnlyAdmin)
    formData.append('types', fileListType)
    Array.from(fileList).map((doc) => {
      formData.append('documents[]', doc)
    })

    if (fileListType.includes(0)) {
      setErrorTypeFiles(true)
      setLoading(false)
      setTimeout(function () {
        setErrorTypeFiles(false)
      }, 3000)
      return
    }

    setErrorTypeFiles(false)

    try {
      const result = await Api.document.add(formData)
      const handling = handlingErrors(result)

      if (handling.status === 401) {
        dispatch(userLogout())
        return
      }

      if (handling.ok) {
        const res = handling.data
        setFileList()
        setLoading(false)
        onSave()
      } else {
        console.log('erreur')
        setLoading(false)
      }
    } catch (error) {
      console.log('erreur submit !')
    }
  }

  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          {t('box.title.addDocuments')}
        </Typography>
        <BoxContainer>
          <form>
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Grid item xs={12} sm={12} lg={12}>
                <InputContainer fullWidth>
                  <ButtonFilesUpload onChange={handleFilesChange} />
                  <ul>
                    {fileList &&
                      Array.from(fileList).map((f, key) => {
                        const src = URL.createObjectURL(f)
                        return (
                          <li
                            key={key}
                            style={{
                              color: !validateSelectedFile(f) ? 'red' : '',
                            }}
                          >
                            {/* {role === 'ROLE_CANDIDAT' &&
                              validateSelectedFile(f) && (
                                <select
                                  onChange={(e) => handleChangeType(e, key, f)}
                                >
                                  <option value="0">
                                    {t('list.documentType')}
                                  </option>
                                  <option value="Cv">
                                    {t('list.documentType1')}
                                  </option>
                                  <option value="Diplômes">
                                    {t('list.documentType2')}
                                  </option>
                                  <option value="Certifications Techniques">
                                    {t('list.documentType3')}
                                  </option>
                                  <option value="Lettre de motivation">
                                    {t('list.documentType4')}
                                  </option>
                                  <option value="Certificat de travail">
                                    {t('list.documentType5')}
                                  </option>
                                  <option value="Recommandations">
                                    {t('list.documentType7')}
                                  </option>
                                  <option value="References">
                                    {t('list.documentType7')}
                                  </option>
                                  <option value="Autres">
                                    {t('list.documentType8')}
                                  </option>
                                </select>
                              )}{' '} */}
                            {f.name} - {f.type}
                            {/* <br></br>
                            {!validateSelectedFile(f) && (
                              <b>{t('errorMessage.fileError1')}</b>
                            )}
                            <br></br>
                            <br></br> */}
                          </li>
                        )
                      })}
                  </ul>

                  {errorSizeFiles && (
                    <Typography
                      className={'error-message'}
                      sx={{ textAlign: 'center' }}
                    >
                      {t('errorMessage.fileError2')}
                      <br></br>
                      <br></br>
                    </Typography>
                  )}
                  {errorTypeFiles && (
                    <Typography
                      className={'error-message'}
                      sx={{ textAlign: 'center' }}
                    >
                      {t('errorMessage.fileError3')}
                      <br></br>
                      <br></br>
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              {isAdmin && (
                <Grid item xs={12} sm={12} lg={12}>
                  <SwitchButton
                    label={"Afficher que pour l'admin"}
                    defaultChecked={isCheckedOnlyAdmin}
                    onChange={switchToggleOnlyAdmin}
                  />{' '}
                  <br></br>
                  {!isCheckedOnlyAdmin && (
                    <Typography variant={'caption'}>
                      {t('errorMessage.fileError4')}
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
          </form>
        </BoxContainer>
        {/*<Box className={"wrapperButtons"} width={"100%"}>*/}
        <Box className={'blockButtons'}>
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            {t('button.cancel')}
          </BorderButtonPurple>
          {loading ? (
            <CircularProgress />
          ) : (
            <CustomButton
              displayIcon={'none'}
              handleClick={handleSubmit(onSubmit)}
            >
              {t('button.save')}
            </CustomButton>
          )}
        </Box>
        {/*</Box>*/}
      </ModalContainer>
    </Modal>
  )
}

export default EditDocuments
