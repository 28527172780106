import React, { useEffect, useState } from 'react'
import { AdminHeading } from '../../../../components/headings'
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  styled,
  Switch,
  TextField,
} from '@mui/material'
import '../../../../styles/components/_box.scss'
import {
  CheckboxInput,
  SearchInputBasic,
  SelectInputObject,
} from '../../../../components/inputs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import { toast } from 'react-toastify'
import lightTheme from '../../../../styles/theme/lightTheme'
import {
  DeleteGeneralModal,
  EditAdresseEntreprise,
} from '../../../../components/modals'
import BesoinTable from '../../../../components/tables/BesoinTable'
import DetailAdresseEntreprise from '../../../../components/modals/DetailAdresseEntreprise'
import { debounce } from '../../../../config'
import { userLogout } from '../../../../store/slices/userAuthReducer'
import { useParams } from 'react-router-dom'
import EditBesoinEntreprise from '../../../../components/modals/EditBesoinEntreprise'
import DetailBesoinEntreprise from '../../../../components/modals/DetailBesoinEntreprise'
import { faClose } from '@fortawesome/pro-solid-svg-icons'
import { useSelector } from 'react-redux'

const WrapperButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const BesoinsEntreprise = () => {
  const params = useParams()
  const userData = useSelector((state) => state.userAuth.user)

  const [loadingDelete, setLoadingDelete] = useState(false)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)

  const handleCloseDeleteBesoinModal = () => setOpenDeleteBesoinModal(false)
  const handleConfirmDeleteBesoinModal = () => removeBesoin()
  const [openDeleteBesoinModal, setOpenDeleteBesoinModal] = useState(false)
  const [openBesoin, setOpenBesoin] = useState(false)
  const [openDetailBesoin, setOpenDetailBesoin] = useState(false)
  const [besoins, setBesoins] = useState([])
  const [besoin, setBesoin] = useState([])
  const [loadingBesoin, setLoadingBesoin] = useState(true)
  const [besoinId, setBesoinId] = useState()

  const [filter, setFilter] = useState([
    { id: 0, titre: 'Entreprise', filtre: 'entreprise.raisonSocial' },
    { id: 1, titre: 'Poste', filtre: 'poste.titre' },
    { id: 2, titre: 'Lieu', filtre: 'search' },
  ])

  const [filterScope, setFilterScope] = useState([
    { id: 0, titre: 'Par default' },
    { id: 1, titre: 'Scope' },
    { id: 2, titre: 'Hors scope' },
  ])
  const [filterSelect, setFilterSelect] = useState(filter[0])
  const [scopeSelect, setScopeSelect] = useState(filterScope[0])

  const [loadingCommerc, setLoadingCommerc] = useState(false)
  const [commercials, setCommercials] = useState([
    { id: null, nom: 'Tous les commerciaux', prenom: '', email: '' },
  ])
  const [commercialSelect, setCommercialSelect] = useState(commercials[0])

  useEffect(() => {
    getBesoinsCompanys(1)
  }, [search, scopeSelect, params.type])

  useEffect(() => {
    if (
      userData.data.role !== 'ROLE_COMMERCIAL' &&
      userData.data.role !== 'ROLE_RECRUTEUR'
    )
      getCommerciaux()
  }, [userData])

  const handleFilterSelect = (event) => {
    const inputValue = event.target.value
    setFilterSelect(inputValue)
  }
  const handleScopeSelect = (event) => {
    const inputValue = event.target.value
    setScopeSelect(inputValue)
  }

  const handleCloseBesoin = () => {
    setOpenBesoin(false)
    //fetchData()
  }
  const handleCloseDetailBesoin = () => {
    setOpenDetailBesoin(false)
    //fetchData()
  }
  const handleSaveBesoin = () => {
    setOpenBesoin(false)
    getBesoinsCompanys(page)
  }

  const handleEditBesoin = (besoin) => {
    setOpenBesoin(true)
    setBesoin(besoin)
  }
  const handleDetailBesoin = (besoin) => {
    setOpenDetailBesoin(true)
    setBesoin(besoin)
  }

  const handleRemoveBesoin = (idBesoin) => {
    setOpenDeleteBesoinModal(true)
    setBesoinId(idBesoin)
  }

  const handlePageChange = (event, page) => {
    setPage(page)
    getBesoinsCompanys(page)
  }

  const handleSearchChange = debounce((event) => {
    const { value } = event.target
    setSearch(value)
  }, 800)

  const getCommerciaux = async () => {
    setLoadingCommerc(true)
    try {
      let result = await Api.user.listWithFilter({
        role: ['ROLE_COMMERCIAL', 'ROLE_RECRUTEUR', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
        status: 'active',
      })

      const handling = handlingErrors(result)

      if (handling.ok) {
        const res = handling.data
        setLoadingCommerc(false)
        setCommercials([...commercials, ...res])
      } else {
        setLoadingCommerc(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoadingCommerc(false)
      console.debug(error)
    }
  }

  useEffect(() => {
    if (
      userData.data.role !== 'ROLE_COMMERCIAL' &&
      userData.data.role !== 'ROLE_RECRUTEUR'
    ) {
      getBesoinsCompanys(1)
    }
  }, [commercialSelect])

  const getBesoinsCompanys = async (page) => {
    setLoadingBesoin(true)

    let payload = {
      search: search,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    if (filterSelect.id != 2) {
      payload = {
        [filterSelect.filtre]: search,
      }
    }
    if (scopeSelect.id === 1 || scopeSelect.id === 2) {
      payload = {
        scope: scopeSelect.id === 1 ? true : false,
      }
    }

    if (commercialSelect && commercialSelect.id) {
      payload.commercial = commercialSelect.id
    }

    const today = new Date()

    if (params.type === 'signer') {
      payload.typeContrat = ['CS', 'MD']
    }

    if (params.type === 'en-cours') {
      // Generate the date two months from today
      const twoMonthsFromNow = new Date()
      twoMonthsFromNow.setMonth(today.getMonth() - 2)

      // Format dates to YYYY-MM-DD, which is typically used for filtering
      const todayFormatted = today.toISOString().split('T')[0]

      payload.dateDebut = {
        strictly_after: todayFormatted,
      }
      payload.typeContrat = ['DC', 'PP', 'PC', 'NT', 'CL']
    }

    if (params.type === 'a-venir') {
      // Generate the date two months from today
      const twoMonthsFromNow = new Date()
      twoMonthsFromNow.setMonth(today.getMonth() + 2)

      // Format dates to YYYY-MM-DD, which is typically used for filtering
      const todayFormatted = today.toISOString().split('T')[0]
      const twoMonthsFromNowFormatted = twoMonthsFromNow
        .toISOString()
        .split('T')[0]

      payload.dateDebut = {
        strictly_after: twoMonthsFromNowFormatted,
      }
      payload.typeContrat = ['DC', 'PP', 'PC', 'NT', 'CL', 'CS', 'MD']
    }

    if (params.type === 'perdu') {
      payload.typeContrat = ['RC']
    }

    try {
      const result =
        await Api.besoinEntreprise.listWithPaginationAndFilterAndOrderBy(
          {
            page: page,
            ...payload,
          },
          'scope',
          'desc'
        )

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setBesoins(res.data['hydra:member'])
        setCount(res.data['hydra:totalItems'])
        /* const sort = res.data['hydra:member'].sort((a, b) =>
          a.date < b.date ? 1 : -1
        ) */
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingBesoin(false)
  }

  const removeBesoin = async () => {
    setLoadingDelete(true)
    try {
      let result = await Api.besoinEntreprise.delete(besoinId)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        const indexBesoin = besoins.findIndex((bs) => bs.id === besoinId)

        if (indexBesoin > -1) {
          besoins.splice(indexBesoin, 1)
        }
        setBesoins(besoins)
        setOpenDeleteBesoinModal(false)
      } else {
        window.scrollTo(0, 0)
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingDelete(false)
    setBesoinId('')
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <AdminHeading displayIconReturn={'none'}>
          Listes des fiches de poste
        </AdminHeading>
        <Box
          className={'BoxContainer'}
          sx={{
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '30px', sm: '55px', lg: '70px' },
          }}
        >
          {/*<Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <SearchInputBasic
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
        </Box>*/}

          <WrapperButton sx={{ columnGap: '15px' }}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} lg={3}>
                <SearchInputBasic onChange={handleSearchChange} />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <SelectInputObject
                  items={filter}
                  value={filterSelect}
                  handleChange={handleFilterSelect}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <SelectInputObject
                  items={filterScope}
                  value={scopeSelect}
                  handleChange={handleScopeSelect}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                {loadingCommerc ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Autocomplete
                      sx={{ width: '100%', backgroundColor: '#fff' }}
                      defaultValue={commercialSelect}
                      options={commercials}
                      disableClearable
                      getOptionLabel={(option) =>
                        option.id
                          ? option.nom +
                            ' ' +
                            option.prenom +
                            ' (' +
                            option.email +
                            ')'
                          : option.nom
                      }
                      onChange={(event, newValue) => {
                        setCommercialSelect(newValue)
                      }}
                      renderTags={(commercials, getTagProps) =>
                        commercials.map((commercial, index) => (
                          <Chip
                            variant="outlined"
                            label={commercial.nom + ' ' + commercial.prenom}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} placeholder={' '} />
                      )}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </WrapperButton>

          <BesoinTable
            besoins={besoins}
            loading={loadingBesoin}
            handleRemove={handleRemoveBesoin}
            handleEdit={handleEditBesoin}
            handleDetailBesoin={handleDetailBesoin}
            handlePageChange={handlePageChange}
            count={count}
            page={page}
            role={'ROLE_ADMIN'}
            showDetail={true}
          />
        </Box>
      </div>

      {openBesoin && (
        <EditBesoinEntreprise
          open={openBesoin}
          onClose={handleCloseBesoin}
          onSave={handleSaveBesoin}
          id={besoin?.entreprise.id}
          idContact={besoin?.contact?.id}
          besoin={besoin}
        />
      )}

      {openDetailBesoin && (
        <DetailBesoinEntreprise
          open={openDetailBesoin}
          onClose={handleCloseDetailBesoin}
          besoin={besoin}
        />
      )}

      {openDeleteBesoinModal && (
        <DeleteGeneralModal
          open={openDeleteBesoinModal}
          onClose={handleCloseDeleteBesoinModal}
          onConfirm={handleConfirmDeleteBesoinModal}
          loading={loadingDelete}
        />
      )}
    </>
  )
}

export default BesoinsEntreprise
