import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  FormControlLabel,
  FormLabel,
  Grid,
  Modal,
  styled,
  Typography,
  Autocomplete,
  TextField,
  Chip,
  Switch,
} from '@mui/material'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import { SelectInputBasic, TextInputClassic } from '../inputs'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { userLogout } from '../../store/slices/userAuthReducer'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/fr'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import TextareaInputClassic from '../inputs/TextareaInputClassic'
import {
  LANGUES,
  SOFTSKILLS,
  STATUS_CONTRAT,
  TYPE_DEVISE,
  niveauLanguesList,
  prioriteEntrepriselList,
} from '../../data'
import {
  faClose,
  faSquarePlus,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
//import isoCountries from "i18n-iso-countries";

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  /*  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8), */
}))

const GroupItems = styled('ul')({
  padding: 0,
})

const DownloadBesoinEntreprise = ({ open, onClose, besoin }) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const [contexte, setContexte] = useState(true)
  const [missions, setMissions] = useState(true)
  const [environnements, setEnvironnements] = useState(true)
  const [competences, setCompetences] = useState(true)
  const [softskills, setSoftskills] = useState(true)
  const [lieu, setLieu] = useState(true)

  const handleChangeContexte = (event) => {
    setContexte(!contexte)
  }
  const handleChangeMissions = (event) => {
    setMissions(!missions)
  }

  const handleChangeEnvironnements = (event) => {
    setEnvironnements(!environnements)
  }

  const handleChangeCompetences = (event) => {
    setCompetences(!competences)
  }

  const handleChangeSoftskills = (event) => {
    setSoftskills(!softskills)
  }

  const handleChangeLieu = (event) => {
    setLieu(!lieu)
  }

  const handleDownloadBesoin = async () => {
    let datas = {
      contexte: contexte,
      missions: missions,
      environnements: environnements,
      competences: competences,
      softskills: softskills,
      lieu: lieu,
    }
/* 
    console.log(datas)

    return */

    setLoading(true)

    try {
      const result = await Api.besoinEntreprise.downloadBc(besoin?.id, datas)

      if (!result.ok) {
        throw new Error('Failed to fetch PDF')
      }

      // Convert the response to a blob
      const blob = await result.blob()

      // Create a download link for the PDF
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'fiche_' + besoin?.id + '.pdf') // Set the desired file name
      document.body.appendChild(link)
      link.click()
      link.remove()
      setLoading(false)
      onClose()
    } catch (error) {
      console.error('Error downloading the PDF:', error)
    }
  }

  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
        overflow: 'auto',
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          Télécharger la fiche
          <Box
            sx={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon icon={faClose} fontSize={25} onClick={onClose} />
          </Box>
        </Typography>
        <BoxContainer>
          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
                alignContent: 'left',
              }}
            >
              <FormLabel className={'labelForm'}>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={handleChangeContexte}
                      defaultChecked={contexte}
                    />
                  }
                />
                {'  '}Afficher le contexte de la mission
              </FormLabel>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
                alignContent: 'left',
              }}
            >
              <FormLabel className={'labelForm'}>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={handleChangeMissions}
                      defaultChecked={missions}
                    />
                  }
                />
                {'  '}Afficher les missions / taches
              </FormLabel>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
                alignContent: 'left',
              }}
            >
              <FormLabel className={'labelForm'}>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={handleChangeEnvironnements}
                      defaultChecked={environnements}
                    />
                  }
                />
                {'  '}Afficher les environnements techniques
              </FormLabel>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
                alignContent: 'left',
              }}
            >
              <FormLabel className={'labelForm'}>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={handleChangeCompetences}
                      defaultChecked={competences}
                    />
                  }
                />
                {'  '}Afficher les compétences obligatoires
              </FormLabel>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
                alignContent: 'left',
              }}
            >
              <FormLabel className={'labelForm'}>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={handleChangeSoftskills}
                      defaultChecked={softskills}
                    />
                  }
                />
                {'  '}Afficher les softskills
              </FormLabel>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
                alignContent: 'left',
              }}
            >
              <FormLabel className={'labelForm'}>
                <FormControlLabel
                  control={
                    <Switch onChange={handleChangeLieu} defaultChecked={lieu} />
                  }
                />
                {'  '}Afficher le lieu
              </FormLabel>
            </Grid>
          </Grid>
        </BoxContainer>
        <Box className={'blockButtons'}>
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            {t('button.cancel')}
          </BorderButtonPurple>
          {loading ? (
            <CircularProgress />
          ) : (
            <CustomButton
              displayIcon={'none'}
              handleClick={handleDownloadBesoin}
            >
              Télécharger
            </CustomButton>
          )}
        </Box>
        {/*</Box>*/}
      </ModalContainer>
    </Modal>
  )
}

export default DownloadBesoinEntreprise
