import React, { useEffect, useState } from 'react'
import { AdminHeading } from '../../../components/headings'
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  FormLabel,
  Grid,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import lightTheme from '../../../styles/theme/lightTheme'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Api from '../../../services/api.service'
import { handlingErrors } from '../../../utils'
import { ListTable } from '../../../components/tables'
import AppPagination from '../../../components/paginations'
import BesoinTable from '../../../components/tables/BesoinTable'
import GaugeComponent from 'react-gauge-component'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import 'dayjs/locale/fr'
import dayjs from 'dayjs'
import { getWeekRange } from '../../../config'
import { faPenCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  height: 435,
  borderRadius: 0,
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
  padding: '15px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  rowGap: '20px',
}))

const TitleBox = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 14,
  lineHeight: '30px',
  letterSpacing: '0.4px',
  color: lightTheme.palette.primary.b2,
  textTransform: 'uppercase',
  minHeight: '30px',
}))

const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const IconContainer = styled(Box)(({ theme }) => ({
  width: 50,
  height: 50,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  '&:hover': {
    borderWidth: '2px',
  },

  '&:active': {
    borderWidth: '1px',
  },
}))

const InputContainer = styled(FormControl)(({ theme }) => ({
  rowGap: '5px',
}))

const ProfilDashboard = () => {
  let navigate = useNavigate()
  const userData = useSelector((state) => state.userAuth.user)
  const [objectifBesoin, setObjectifBesoin] = useState(
    userData?.data?.objectifBesoin ? userData?.data?.objectifBesoin : 0
  )
  const [objectifPositionnement, setObjectifPositionnement] = useState(
    userData?.data?.objectifPositionnement
      ? userData?.data?.objectifPositionnement
      : 0
  )
  const [objectifRdv, setObjectifRdv] = useState(
    userData?.data?.objectifRdv ? userData?.data?.objectifRdv : 0
  )
  const [objectifPresentation, setObjectifPresentation] = useState(
    userData?.data?.objectifPresentation
      ? userData?.data?.objectifPresentation
      : 0
  )
  const [objectifSignature, setObjectifSignature] = useState(
    userData?.data?.objectifSignature ? userData?.data?.objectifSignature : 0
  )
  const [objectifContact, setObjectifContact] = useState(
    userData?.data?.objectifContact ? userData?.data?.objectifContact : 0
  )
  const [totalContact, setTotalContact] = useState(0)
  const [totalBesoin, setTotalBesoin] = useState(0)
  const [totalPresentation, setTotalPresentation] = useState(0)
  const [totalRdv, setTotalRdv] = useState(0)
  const [totalPositionnement, setTotalPositionnement] = useState(0)
  const [totalSignature, setTotalSignature] = useState(0)
  const [loading, setLoading] = useState(true)

  const [loadingEntreprise, setLoadingEntreprise] = useState(false)
  const [companys, setCompanys] = useState([])
  const [companysCount, setCompanysCount] = useState(0)
  const [id, setId] = useState(null)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [commercial, setCommercial] = useState(
    userData.data.role === 'ROLE_COMMERCIAL' ||
      userData.data.role === 'ROLE_RECRUTEUR'
      ? userData.data.id
      : ''
  )

  const [pageBesoin, setPageBesoin] = useState(1)
  const [countBesoin, setCountBesoin] = useState(0)
  const [besoins, setBesoins] = useState([])
  const [loadingBesoin, setLoadingBesoin] = useState(true)

  const [loadingCommerc, setLoadingCommerc] = useState(false)
  const [commercials, setCommercials] = useState([
    { id: null, nom: 'Tous', prenom: '', email: '' },
  ])
  const [commercialSelect, setCommercialSelect] = useState(commercials[0])

  const weekRange = getWeekRange()

  const [debut, setDebut] = useState(dayjs(weekRange.firstDay))
  const [fin, setFin] = useState(dayjs(weekRange.lastDay))

  const getDashboard = async () => {
    setLoading(true)
    let data = {}
    if (commercialSelect && commercialSelect.id) {
      data = { commercial: commercialSelect.id }
    }

    if (
      userData.data.role !== 'ROLE_COMMERCIAL' &&
      userData.data.role !== 'ROLE_RECRUTEUR'
    ) {
      data.debut = debut.format('DD/MM/YYYY')
      data.fin = fin.format('DD/MM/YYYY')
    }

    try {
      let result = await Api.user.dashboard(data)

      const handling = handlingErrors(result)

      if (handling.ok) {
        const res = handling
        setLoading(false)
        setTotalContact(res.data.contact)
        setTotalBesoin(res.data.besoin)
        setTotalPositionnement(res.data.positionnement)
        setTotalPresentation(res.data.presentation)
        setTotalRdv(res.data.rdv)
        setTotalSignature(res.data.signature)
        setObjectifBesoin(
          res.data.objectifBesoin > 0 ? res.data.objectifBesoin : 0
        )
        setObjectifPositionnement(
          res.data.objectifPositionnement > 0
            ? res.data.objectifPositionnement
            : 0
        )
        setObjectifRdv(res.data.objectifRdv > 0 ? res.data.objectifRdv : 0)
        setObjectifPresentation(
          res.data.objectifPresentation > 0 ? res.data.objectifPresentation : 0
        )
        setObjectifSignature(
          res.data.objectifSignature > 0 ? res.data.objectifSignature : 0
        )
        setObjectifContact(
          res.data.objectifContact > 0 ? res.data.objectifContact : 0
        )
      } else {
        setLoading(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      console.debug(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getDashboard()
  }, [userData.data, debut, fin])

  useEffect(() => {
    if (
      userData.data.role !== 'ROLE_COMMERCIAL' &&
      userData.data.role !== 'ROLE_RECRUTEUR'
    ) {
      setCommercial(commercialSelect?.id)
      getDashboard()
    }
  }, [commercialSelect])

  const getCompanys = async (page) => {
    setLoadingEntreprise(true)

    let payload = {
      search: search,
    }
    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )

    try {
      const result =
        await Api.user.listWithPaginationAndFilterAndOrderByPriorite({
          role: 'ROLE_ENTREPRISE',
          entreprises: commercial,
          priorite: [3, 2],
          //entreprises: (userData.data.role === 'ROLE_COMMERCIAL') ? userData.data.id : '',
          page: page,
          ...payload,
        })

      const res = handlingErrors(result)

      if (res.ok) {
        setCompanys(res.data['hydra:member'])
        setCount(res.data['hydra:totalItems'])
        setCompanysCount(res.data['hydra:totalItems'])
        /* const sort = res.data['hydra:member'].sort((a, b) =>
          a.date < b.date ? 1 : -1
        ) */
      }
    } catch (error) {
      console.debug(error)
    }
    setLoadingEntreprise(false)
  }

  useEffect(() => {
    getCompanys(page)
  }, [id, search, commercial])

  const handlePageChange = (event, page) => {
    setPage(page)
    getCompanys(page)
  }

  const handleClickToProfil = (userId) => {
    navigate(`/admin/gestion-entreprise/profil-entreprise/${userId}`)
  }

  const getBesoinsCompanys = async (page) => {
    setLoadingBesoin(true)

    let payload = {
      search: search,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )

    try {
      const result =
        await Api.besoinEntreprise.listWithPaginationAndFilterAndOrderByPriorite(
          {
            commercial: commercial,
            priorite: [3, 2],
            page: page,
            ...payload,
          }
        )

      const res = handlingErrors(result)

      if (res.ok) {
        setBesoins(res.data['hydra:member'])
        setCountBesoin(res.data['hydra:totalItems'])
        setLoadingBesoin(false)
      }
    } catch (error) {
      console.debug(error)
      setLoadingBesoin(false)
    }
  }

  useEffect(() => {
    getBesoinsCompanys(pageBesoin)
  }, [commercial])

  const handlePageBesoinChange = (event, page) => {
    setPageBesoin(page)
    getBesoinsCompanys(page)
  }

  const getCommerciaux = async () => {
    setLoadingCommerc(true)
    try {
      let result = await Api.user.listWithFilter({
        role: ['ROLE_COMMERCIAL', 'ROLE_RECRUTEUR'],
        status: 'active',
      })

      const handling = handlingErrors(result)

      if (handling.ok) {
        const res = handling.data
        setLoadingCommerc(false)
        setCommercials([...commercials, ...res])
      } else {
        setLoadingCommerc(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoadingCommerc(false)
      console.debug(error)
    }
  }

  useEffect(() => {
    if (
      userData.data.role !== 'ROLE_COMMERCIAL' &&
      userData.data.role !== 'ROLE_RECRUTEUR'
    )
      getCommerciaux()
  }, [userData])

  if (
    userData.data.role === 'ROLE_COMMERCIAL' ||
    userData.data.role === 'ROLE_RECRUTEUR'
  )
    return (
      <div style={{ width: '100%' }}>
        <AdminHeading displayIconReturn={'none'}>
          Bienvenue {userData.data.prenom}
        </AdminHeading>
        <Box
          sx={{
            width: 'calc(100% - 50px)',
            height: '100%',
            margin: {
              xs: '75px 25px 25px 25px',
              sm: '105px 25px 25px 25px',
              lg: '95px 25px 25px 25px',
            },
          }}
        >
          <Container
            maxWidth={'xl'}
            sx={{ paddingTop: '25px', paddingBottom: '25px' }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} lg={4}>
                <Item
                  sx={{
                    height: '100%',
                  }}
                >
                  <TitleContainer>
                    <TitleBox>
                      <>Mes objectifs / semaine</>
                    </TitleBox>{' '}
                    {commercialSelect?.id && (
                      <IconContainer
                        onClick={() =>
                          navigate(
                            '/admin/gestion-commerciaux/profil-commercial/' +
                              commercialSelect.id
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faPenCircle}
                          fontSize={40}
                          color={lightTheme.palette.primary.b2}
                        />
                      </IconContainer>
                    )}
                  </TitleContainer>

                  <Box>
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <>
                        <CardHeader
                          sx={{ padding: 0, marginBottom: '5px' }}
                          avatar={
                            <Avatar
                              sx={{ bgcolor: '#03b2cb' }}
                              aria-label="recipe"
                            >
                              {objectifBesoin}
                            </Avatar>
                          }
                          title="Besoins"
                          subheader=""
                        />
                        <CardHeader
                          sx={{ padding: 0, marginBottom: '5px' }}
                          avatar={
                            <Avatar
                              sx={{ bgcolor: '#03b2cb' }}
                              aria-label="recipe"
                            >
                              {objectifPositionnement}
                            </Avatar>
                          }
                          title="Positionnements"
                          subheader=""
                        />
                        <CardHeader
                          sx={{ padding: 0, marginBottom: '5px' }}
                          avatar={
                            <Avatar
                              sx={{ bgcolor: '#03b2cb' }}
                              aria-label="recipe"
                            >
                              {objectifRdv}
                            </Avatar>
                          }
                          title="RDVs"
                          subheader=""
                        />
                        <CardHeader
                          sx={{ padding: 0, marginBottom: '5px' }}
                          avatar={
                            <Avatar
                              sx={{ bgcolor: '#03b2cb' }}
                              aria-label="recipe"
                            >
                              {objectifPresentation}
                            </Avatar>
                          }
                          title="Présentations"
                          subheader=""
                        />
                        <CardHeader
                          sx={{ padding: 0 }}
                          avatar={
                            <Avatar
                              sx={{ bgcolor: '#03b2cb' }}
                              aria-label="recipe"
                            >
                              {objectifSignature}
                            </Avatar>
                          }
                          title="Signatures"
                          subheader=""
                        />
                      </>
                    )}
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Item
                  sx={{
                    height: '100%',
                  }}
                >
                  <TitleContainer>
                    <TitleBox>Besoins ({totalBesoin})</TitleBox>
                  </TitleContainer>

                  <Box>
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <GaugeComponent
                        type="semicircle"
                        arc={{
                          width: 0.2,
                          padding: 0.005,
                          cornerRadius: 1,
                          // gradient: true,
                          subArcs: [
                            {
                              limit:
                                objectifBesoin > 0 ? objectifBesoin / 5 : 1,
                              color: '#EA4228',
                              showTick: true,
                              tooltip: {
                                text: 'Lundi',
                              },
                              /*  onClick: () =>
                            console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'),
                          onMouseMove: () =>
                            console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB'),
                          onMouseLeave: () =>
                            console.log('CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC'), */
                            },
                            {
                              limit:
                                (objectifBesoin > 0 ? objectifBesoin / 5 : 1) *
                                2,
                              color: '#F58B19',
                              showTick: true,
                              tooltip: {
                                text: 'Mardi',
                              },
                            },
                            {
                              limit:
                                (objectifBesoin > 0 ? objectifBesoin / 5 : 1) *
                                3,
                              color: '#F58B19',
                              showTick: true,
                              tooltip: {
                                text: 'Mercredi',
                              },
                            },
                            {
                              limit:
                                (objectifBesoin > 0 ? objectifBesoin / 5 : 1) *
                                4,
                              color: '#F5CD19',
                              showTick: true,
                              tooltip: {
                                text: 'Jeudi',
                              },
                            },
                            {
                              limit:
                                (objectifBesoin > 0 ? objectifBesoin / 5 : 1) *
                                5,
                              color: '#5BE12C',
                              tooltip: {
                                text: 'Vendredi',
                              },
                            },
                          ],
                        }}
                        pointer={{
                          color: '#345243',
                          length: 0.8,
                          width: 15,
                          // elastic: true,
                        }}
                        labels={{
                          valueLabel: { formatTextValue: (value) => value },
                          tickLabels: {
                            type: 'outer',
                            defaultTickValueConfig: {
                              formatTextValue: (value) => value,
                              style: { fontSize: 10 },
                            },
                          },
                        }}
                        value={totalBesoin}
                        minValue={0}
                        maxValue={objectifBesoin > 0 ? objectifBesoin : 5}
                      />
                    )}
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Item
                  sx={{
                    height: '100%',
                  }}
                >
                  <TitleContainer>
                    <TitleBox>Positionnement ({totalPositionnement})</TitleBox>
                  </TitleContainer>

                  <Box>
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <GaugeComponent
                        type="semicircle"
                        arc={{
                          width: 0.2,
                          padding: 0.005,
                          cornerRadius: 1,
                          // gradient: true,
                          subArcs: [
                            {
                              limit: objectifPositionnement > 0 ? objectifPositionnement / 5 : 1,
                              color: '#EA4228',
                              showTick: true,
                              tooltip: {
                                text: 'Lundi',
                              },
                              /*  onClick: () =>
                            console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'),
                          onMouseMove: () =>
                            console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB'),
                          onMouseLeave: () =>
                            console.log('CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC'), */
                            },
                            {
                              limit: (objectifPositionnement > 0 ? objectifPositionnement / 5 : 1) * 2,
                              color: '#F58B19',
                              showTick: true,
                              tooltip: {
                                text: 'Mardi',
                              },
                            },
                            {
                              limit: (objectifPositionnement > 0 ? objectifPositionnement / 5 : 1)  * 3,
                              color: '#F58B19',
                              showTick: true,
                              tooltip: {
                                text: 'Mercredi',
                              },
                            },
                            {
                              limit: (objectifPositionnement > 0 ? objectifPositionnement / 5 : 1)  * 4,
                              color: '#F5CD19',
                              showTick: true,
                              tooltip: {
                                text: 'Jeudi',
                              },
                            },
                            {
                              limit: (objectifPositionnement > 0 ? objectifPositionnement / 5 : 1)  * 5,
                              color: '#5BE12C',
                              tooltip: {
                                text: 'Vendredi',
                              },
                            },
                          ],
                        }}
                        pointer={{
                          color: '#345243',
                          length: 0.8,
                          width: 15,
                          // elastic: true,
                        }}
                        labels={{
                          valueLabel: { formatTextValue: (value) => value },
                          tickLabels: {
                            type: 'outer',
                            defaultTickValueConfig: {
                              formatTextValue: (value) => value,
                              style: { fontSize: 10 },
                            },
                          },
                        }}
                        value={totalPositionnement}
                        minValue={0}
                        maxValue={objectifPositionnement > 0 ? objectifPositionnement : 5}
                      />
                    )}
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Item
                  sx={{
                    height: '100%',
                  }}
                >
                  <TitleContainer>
                    <TitleBox>Rendez-vous ({totalRdv})</TitleBox>
                  </TitleContainer>

                  <Box>
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <GaugeComponent
                        type="semicircle"
                        arc={{
                          width: 0.2,
                          padding: 0.005,
                          cornerRadius: 1,
                          // gradient: true,
                          subArcs: [
                            {
                              limit: objectifRdv > 0 ? objectifRdv / 5 : 1,
                              color: '#EA4228',
                              showTick: true,
                              tooltip: {
                                text: 'Lundi',
                              },
                              /*  onClick: () =>
                            console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'),
                          onMouseMove: () =>
                            console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB'),
                          onMouseLeave: () =>
                            console.log('CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC'), */
                            },
                            {
                              limit: (objectifRdv > 0 ? objectifRdv / 5 : 1) * 2,
                              color: '#F58B19',
                              showTick: true,
                              tooltip: {
                                text: 'Mardi',
                              },
                            },
                            {
                              limit: (objectifRdv > 0 ? objectifRdv / 5 : 1) * 3,
                              color: '#F58B19',
                              showTick: true,
                              tooltip: {
                                text: 'Mercredi',
                              },
                            },
                            {
                              limit: (objectifRdv > 0 ? objectifRdv / 5 : 1) * 4,
                              color: '#F5CD19',
                              showTick: true,
                              tooltip: {
                                text: 'Jeudi',
                              },
                            },
                            {
                              limit: (objectifRdv > 0 ? objectifRdv / 5 : 1) * 5,
                              color: '#5BE12C',
                              tooltip: {
                                text: 'Vendredi',
                              },
                            },
                          ],
                        }}
                        pointer={{
                          color: '#345243',
                          length: 0.8,
                          width: 15,
                          // elastic: true,
                        }}
                        labels={{
                          valueLabel: { formatTextValue: (value) => value },
                          tickLabels: {
                            type: 'outer',
                            defaultTickValueConfig: {
                              formatTextValue: (value) => value,
                              style: { fontSize: 10 },
                            },
                          },
                        }}
                        value={totalRdv}
                        minValue={0}
                        maxValue={objectifRdv > 0 ? objectifRdv : 5}
                      />
                    )}
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Item
                  sx={{
                    height: '100%',
                  }}
                >
                  <TitleContainer>
                    <TitleBox>
                      Présentations client ({totalPresentation})
                    </TitleBox>
                  </TitleContainer>

                  <Box>
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <GaugeComponent
                        type="semicircle"
                        arc={{
                          width: 0.2,
                          padding: 0.005,
                          cornerRadius: 1,
                          // gradient: true,
                          subArcs: [
                            {
                              limit: objectifPresentation > 0 ? objectifPresentation / 5 : 1,
                              color: '#EA4228',
                              showTick: true,
                              tooltip: {
                                text: 'Lundi',
                              },
                              /*  onClick: () =>
                            console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'),
                          onMouseMove: () =>
                            console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB'),
                          onMouseLeave: () =>
                            console.log('CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC'), */
                            },
                            {
                              limit: (objectifPresentation > 0 ? objectifPresentation / 5 : 1) * 2,
                              color: '#F58B19',
                              showTick: true,
                              tooltip: {
                                text: 'Mardi',
                              },
                            },
                            {
                              limit: (objectifPresentation > 0 ? objectifPresentation / 5 : 1) * 3,
                              color: '#F58B19',
                              showTick: true,
                              tooltip: {
                                text: 'Mercredi',
                              },
                            },
                            {
                              limit: (objectifPresentation > 0 ? objectifPresentation / 5 : 1) * 4,
                              color: '#F5CD19',
                              showTick: true,
                              tooltip: {
                                text: 'Jeudi',
                              },
                            },
                            {
                              limit: (objectifPresentation > 0 ? objectifPresentation / 5 : 1) * 5,
                              color: '#5BE12C',
                              tooltip: {
                                text: 'Vendredi',
                              },
                            },
                          ],
                        }}
                        pointer={{
                          color: '#345243',
                          length: 0.8,
                          width: 15,
                          // elastic: true,
                        }}
                        labels={{
                          valueLabel: { formatTextValue: (value) => value },
                          tickLabels: {
                            type: 'outer',
                            defaultTickValueConfig: {
                              formatTextValue: (value) => value,
                              style: { fontSize: 10 },
                            },
                          },
                        }}
                        value={totalPresentation}
                        minValue={0}
                        maxValue={objectifPresentation > 0 ? objectifPresentation : 5}
                      />
                    )}
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Item
                  sx={{
                    height: '100%',
                  }}
                >
                  <TitleContainer>
                    <TitleBox>Signatures ({totalSignature})</TitleBox>
                  </TitleContainer>

                  <Box>
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <GaugeComponent
                        type="semicircle"
                        arc={{
                          width: 0.2,
                          padding: 0.005,
                          cornerRadius: 1,
                          // gradient: true,
                          subArcs: [
                            {
                              limit: (objectifSignature > 0 ? objectifSignature / 5 : 1),
                              color: '#EA4228',
                              showTick: true,
                              tooltip: {
                                text: 'Lundi',
                              },
                              /*  onClick: () =>
                            console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'),
                          onMouseMove: () =>
                            console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB'),
                          onMouseLeave: () =>
                            console.log('CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC'), */
                            },
                            {
                              limit: (objectifSignature > 0 ? objectifSignature / 5 : 1) * 2,
                              color: '#F58B19',
                              showTick: true,
                              tooltip: {
                                text: 'Mardi',
                              },
                            },
                            {
                              limit: (objectifSignature > 0 ? objectifSignature / 5 : 1) * 3,
                              color: '#F58B19',
                              showTick: true,
                              tooltip: {
                                text: 'Mercredi',
                              },
                            },
                            {
                              limit: (objectifSignature > 0 ? objectifSignature / 5 : 1) * 4,
                              color: '#F5CD19',
                              showTick: true,
                              tooltip: {
                                text: 'Jeudi',
                              },
                            },
                            {
                              limit: (objectifSignature > 0 ? objectifSignature / 5 : 1) * 5,
                              color: '#5BE12C',
                              tooltip: {
                                text: 'Vendredi',
                              },
                            },
                          ],
                        }}
                        pointer={{
                          color: '#345243',
                          length: 0.8,
                          width: 15,
                          // elastic: true,
                        }}
                        labels={{
                          valueLabel: { formatTextValue: (value) => value },
                          tickLabels: {
                            type: 'outer',
                            defaultTickValueConfig: {
                              formatTextValue: (value) => value,
                              style: { fontSize: 10 },
                            },
                          },
                        }}
                        value={totalSignature}
                        minValue={0}
                        maxValue={objectifSignature > 0 ? objectifSignature : 5}
                      />
                    )}
                  </Box>
                </Item>
              </Grid>

              <Grid item xs={12} sm={12} lg={12}>
                <Item
                  sx={{
                    height: '100%',
                  }}
                >
                  <TitleContainer>
                    <TitleBox>Liste des entreprises ({companysCount})</TitleBox>
                  </TitleContainer>
                  <ListTable
                    type={'entreprise'}
                    items={companys}
                    setId={setId}
                    loading={loadingEntreprise}
                    handleClick={handleClickToProfil}
                    /* handleRemove={handleRemoveProfil} */
                  />
                  <AppPagination
                    handlePageChange={handlePageChange}
                    count={count}
                    page={page}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Item
                  sx={{
                    height: '100%',
                  }}
                >
                  <TitleContainer>
                    <TitleBox>
                      Listes des fiches de poste ({countBesoin})
                    </TitleBox>
                  </TitleContainer>
                  <BesoinTable
                    besoins={besoins}
                    loading={loadingBesoin}
                    /* handleRemove={handleRemoveBesoin}
                  handleEdit={handleEditBesoin}
                  handleDetailBesoin={handleDetailBesoin} */
                    handlePageChange={handlePageBesoinChange}
                    count={countBesoin}
                    page={pageBesoin}
                    role={'ROLE_ADMIN'}
                    showDetail={true}
                    showEdit={false}
                  />
                </Item>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </div>
    )

  return (
    <div style={{ width: '100%' }}>
      <AdminHeading displayIconReturn={'none'}>
        Bienvenue {userData.data.prenom}
      </AdminHeading>
      <Box
        sx={{
          width: 'calc(100% - 50px)',
          height: '100%',
          margin: {
            xs: '75px 25px 25px 25px',
            sm: '105px 25px 25px 25px',
            lg: '95px 25px 25px 25px',
          },
        }}
      >
        <Container
          maxWidth={'xl'}
          sx={{ paddingTop: '25px', paddingBottom: '25px' }}
        >
          <Grid container spacing={4}>
            {userData.data.role !== 'ROLE_COMMERCIAL' &&
              userData.data.role !== 'ROLE_RECRUTEUR' && (
                <>
                  <Grid item xs={12} sm={6} lg={6}>
                    <InputContainer fullWidth>
                      <FormLabel className={'labelForm'}>
                        Filtrer par commercial
                      </FormLabel>

                      {loadingCommerc ? (
                        <CircularProgress />
                      ) : (
                        <>
                          <Autocomplete
                            sx={{ width: '100%', backgroundColor: '#fff' }}
                            defaultValue={commercialSelect}
                            options={commercials}
                            disableClearable
                            getOptionLabel={(option) =>
                              option.id
                                ? option.nom +
                                  ' ' +
                                  option.prenom +
                                  ' (' +
                                  option.email +
                                  ')'
                                : option.nom
                            }
                            onChange={(event, newValue) => {
                              setCommercialSelect(newValue)
                            }}
                            renderTags={(commercials, getTagProps) =>
                              commercials.map((commercial, index) => (
                                <Chip
                                  variant="outlined"
                                  label={
                                    commercial.nom + ' ' + commercial.prenom
                                  }
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField {...params} placeholder={' '} />
                            )}
                          />
                        </>
                      )}
                    </InputContainer>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6}>
                    <InputContainer fullWidth>
                      <FormLabel className={'labelForm'}>
                        Filtrer par date
                      </FormLabel>
                      <Grid container spacing={4}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={'fr'}
                        >
                          <Grid item lg={6}>
                            <DatePicker
                              onChange={setDebut}
                              value={debut}
                              inputFormat={'DD/MM/YYYY'}
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    paddingLeft: '5px',
                                    height: 45,
                                    backgroundColor:
                                      lightTheme.palette.primary.b2,
                                    borderRadius: '5px',
                                    color: '#FFFFFF',
                                    fontWeight: 'bold',

                                    '& .css-1laqsz7-MuiInputAdornment-root, & .MuiInputAdornment-root':
                                      {
                                        height: '100%',
                                        width: '50px',
                                        margin: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',

                                        '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root, & .MuiButtonBase-root.MuiIconButton-root':
                                          {
                                            margin: 0,
                                            color: '#FFFFFF',
                                          },
                                      },
                                  }}
                                >
                                  {' '}
                                  {'De'}
                                  <input
                                    ref={inputRef}
                                    placeholder={'Date debut'}
                                    {...inputProps}
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      marginLeft: '5px',
                                      borderRadius: '5px 0 0 5px',
                                      border: '1px solid #9E9E9E',
                                      padding: '10px 15px',
                                      fontFamily: 'Century Gothic',
                                      fontSize: 14,
                                      lineHeight: '140%',
                                      letterSpacing: '0.2px',
                                      color: lightTheme.palette.text.primary,
                                      backgroundColor: '#FFFFFF',
                                    }}
                                  />
                                  {InputProps?.endAdornment}
                                </Box>
                              )}
                            />
                          </Grid>
                          <Grid item lg={6}>
                            <DatePicker
                              onChange={setFin}
                              value={fin}
                              inputFormat={'DD/MM/YYYY'}
                              minDate={debut}
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    paddingLeft: '5px',
                                    height: 45,
                                    backgroundColor:
                                      lightTheme.palette.primary.b2,
                                    borderRadius: '5px',
                                    color: '#FFFFFF',
                                    fontWeight: 'bold',

                                    '& .css-1laqsz7-MuiInputAdornment-root, & .MuiInputAdornment-root':
                                      {
                                        height: '100%',
                                        width: '50px',
                                        margin: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',

                                        '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root, & .MuiButtonBase-root.MuiIconButton-root':
                                          {
                                            margin: 0,
                                            color: '#FFFFFF',
                                          },
                                      },
                                  }}
                                >
                                  {' '}
                                  {' Au '}
                                  <input
                                    ref={inputRef}
                                    {...inputProps}
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      borderRadius: '5px 0 0 5px',
                                      border: '1px solid #9E9E9E',
                                      padding: '10px 15px',
                                      marginLeft: '5px',
                                      fontFamily: 'Century Gothic',
                                      fontSize: 14,
                                      lineHeight: '140%',
                                      letterSpacing: '0.2px',
                                      color: lightTheme.palette.text.primary,
                                      backgroundColor: '#FFFFFF',
                                    }}
                                  />
                                  {InputProps?.endAdornment}
                                </Box>
                              )}
                            />
                          </Grid>
                        </LocalizationProvider>
                      </Grid>
                    </InputContainer>
                  </Grid>
                </>
              )}
            {/* {commercialSelect?.id ? ( */}
            <>
              <Grid item xs={12} sm={12} lg={12}>
                <Item
                  sx={{
                    height: '100%',
                  }}
                >
                  <TitleContainer>
                    <TitleBox>
                      <>Objectifs / semaine</>
                    </TitleBox>{' '}
                    {commercialSelect?.id && (
                      <IconContainer
                        onClick={() =>
                          navigate(
                            '/admin/gestion-commerciaux/profil-commercial/' +
                              commercialSelect.id
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faPenCircle}
                          fontSize={40}
                          color={lightTheme.palette.primary.b2}
                        />
                      </IconContainer>
                    )}
                  </TitleContainer>

                  <Box>
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} lg={4}>
                          <CardHeader
                            sx={{ padding: 0 }}
                            avatar={
                              <Avatar
                                sx={{ bgcolor: '#03b2cb' }}
                                aria-label="recipe"
                              >
                                {objectifBesoin}
                              </Avatar>
                            }
                            title="Besoins"
                            subheader=""
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <CardHeader
                            sx={{ padding: 0 }}
                            avatar={
                              <Avatar
                                sx={{ bgcolor: '#03b2cb' }}
                                aria-label="recipe"
                              >
                                {objectifContact}
                              </Avatar>
                            }
                            title="Contacts"
                            subheader=""
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <CardHeader
                            sx={{ padding: 0 }}
                            avatar={
                              <Avatar
                                sx={{ bgcolor: '#03b2cb' }}
                                aria-label="recipe"
                              >
                                {objectifPositionnement}
                              </Avatar>
                            }
                            title="Positionnements"
                            subheader=""
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <CardHeader
                            sx={{ padding: 0 }}
                            avatar={
                              <Avatar
                                sx={{ bgcolor: '#03b2cb' }}
                                aria-label="recipe"
                              >
                                {objectifRdv}
                              </Avatar>
                            }
                            title="RDVs"
                            subheader=""
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <CardHeader
                            sx={{ padding: 0 }}
                            avatar={
                              <Avatar
                                sx={{ bgcolor: '#03b2cb' }}
                                aria-label="recipe"
                              >
                                {objectifPresentation}
                              </Avatar>
                            }
                            title="Présentations"
                            subheader=""
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <CardHeader
                            sx={{ padding: 0 }}
                            avatar={
                              <Avatar
                                sx={{ bgcolor: '#03b2cb' }}
                                aria-label="recipe"
                              >
                                {objectifSignature}
                              </Avatar>
                            }
                            title="Signatures"
                            subheader=""
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Item
                  sx={{
                    height: '100%',
                  }}
                >
                  <TitleContainer>
                    <TitleBox>Nombre de contacts ({totalContact})</TitleBox>
                  </TitleContainer>

                  <Box>
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <GaugeComponent
                        type="semicircle"
                        arc={{
                          width: 0.2,
                          padding: 0.005,
                          cornerRadius: 1,
                          // gradient: true,
                          subArcs: [
                            {
                              limit:
                                objectifContact > 0 ? objectifContact / 5 : 1,
                              color: '#EA4228',
                              showTick: true,
                              tooltip: {
                                text: 'Lundi',
                              },
                              /*  onClick: () =>
                            console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'),
                          onMouseMove: () =>
                            console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB'),
                          onMouseLeave: () =>
                            console.log('CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC'), */
                            },
                            {
                              limit:
                                (objectifContact > 0
                                  ? objectifContact / 5
                                  : 1) * 2,
                              color: '#F58B19',
                              showTick: true,
                              tooltip: {
                                text: 'Mardi',
                              },
                            },
                            {
                              limit:
                                (objectifContact > 0
                                  ? objectifContact / 5
                                  : 1) * 3,
                              color: '#F58B19',
                              showTick: true,
                              tooltip: {
                                text: 'Mercredi',
                              },
                            },
                            {
                              limit:
                                (objectifContact > 0
                                  ? objectifContact / 5
                                  : 1) * 4,
                              color: '#F5CD19',
                              showTick: true,
                              tooltip: {
                                text: 'Jeudi',
                              },
                            },
                            {
                              limit:
                                (objectifContact > 0
                                  ? objectifContact / 5
                                  : 1) * 5,
                              color: '#5BE12C',
                              tooltip: {
                                text: 'Vendredi',
                              },
                            },
                          ],
                        }}
                        pointer={{
                          color: '#345243',
                          length: 0.8,
                          width: 15,
                          // elastic: true,
                        }}
                        labels={{
                          valueLabel: { formatTextValue: (value) => value },
                          tickLabels: {
                            type: 'outer',
                            defaultTickValueConfig: {
                              formatTextValue: (value) => value,
                              style: { fontSize: 10 },
                            },
                          },
                        }}
                        value={totalContact}
                        minValue={0}
                        maxValue={objectifContact > 0 ? objectifContact : 5}
                      />
                    )}
                  </Box>
                </Item>
              </Grid>
            </>

            <Grid item xs={12} sm={6} lg={4}>
              <Item
                sx={{
                  height: '100%',
                }}
              >
                <TitleContainer>
                  <TitleBox>Ratio besoins ({totalBesoin})</TitleBox>
                </TitleContainer>

                <Box>
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <GaugeComponent
                      type="semicircle"
                      arc={{
                        width: 0.2,
                        padding: 0.005,
                        cornerRadius: 1,
                        gradient: true,
                        subArcs: [
                          {
                            limit: 20,
                            color: '#EA4228',
                            /*  onClick: () =>
                            console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'),
                          onMouseMove: () =>
                            console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB'),
                          onMouseLeave: () =>
                            console.log('CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC'), */
                          },
                          {
                            limit: 40,
                            color: '#F58B19',
                          },
                          {
                            limit: 60,
                            color: '#F58B19',
                          },
                          {
                            limit: 80,
                            color: '#F5CD19',
                          },
                          {
                            color: '#5BE12C',
                          },
                        ],
                      }}
                      pointer={{
                        color: '#345243',
                        length: 0.8,
                        width: 15,
                        // elastic: true,
                      }}
                      labels={{
                        valueLabel: { formatTextValue: (value) => value + '%' },
                        tickLabels: {
                          type: 'outer',
                          defaultTickValueConfig: {
                            formatTextValue: (value) => value + '%',
                            style: { fontSize: 10 },
                          },
                        },
                      }}
                      value={
                        totalBesoin === 0 || totalContact === 0
                          ? 0
                          : (totalBesoin / totalContact) * 100
                      }
                      minValue={0}
                      maxValue={100}
                    />
                  )}
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Item
                sx={{
                  height: '100%',
                }}
              >
                <TitleContainer>
                  <TitleBox>
                    Ratio positionnement(s) profil(s) ({totalPositionnement})
                  </TitleBox>
                </TitleContainer>

                <Box>
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <GaugeComponent
                      type="semicircle"
                      arc={{
                        width: 0.2,
                        padding: 0.005,
                        cornerRadius: 1,
                        gradient: true,
                        subArcs: [
                          {
                            limit: 20,
                            color: '#EA4228',
                            /*  onClick: () =>
                console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'),
              onMouseMove: () =>
                console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB'),
              onMouseLeave: () =>
                console.log('CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC'), */
                          },
                          {
                            limit: 40,
                            color: '#F58B19',
                          },
                          {
                            limit: 60,
                            color: '#F58B19',
                          },
                          {
                            limit: 80,
                            color: '#F5CD19',
                          },
                          {
                            color: '#5BE12C',
                          },
                        ],
                      }}
                      pointer={{
                        color: '#345243',
                        length: 0.8,
                        width: 15,
                        // elastic: true,
                      }}
                      labels={{
                        valueLabel: { formatTextValue: (value) => value + '%' },
                        tickLabels: {
                          type: 'outer',
                          defaultTickValueConfig: {
                            formatTextValue: (value) => value + '%',
                            style: { fontSize: 10 },
                          },
                        },
                      }}
                      value={
                        totalPositionnement === 0 && totalContact === 0
                          ? 0
                          : (totalPositionnement / totalContact) * 100
                      }
                      minValue={0}
                      maxValue={100}
                    />
                  )}
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Item
                sx={{
                  height: '100%',
                }}
              >
                <TitleContainer>
                  <TitleBox>Ratio RDV ({totalRdv})</TitleBox>
                </TitleContainer>

                <Box>
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <GaugeComponent
                      type="semicircle"
                      arc={{
                        width: 0.2,
                        padding: 0.005,
                        cornerRadius: 1,
                        gradient: true,
                        subArcs: [
                          {
                            limit: 20,
                            color: '#EA4228',
                            /*  onClick: () =>
                console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'),
              onMouseMove: () =>
                console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB'),
              onMouseLeave: () =>
                console.log('CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC'), */
                          },
                          {
                            limit: 40,
                            color: '#F58B19',
                          },
                          {
                            limit: 60,
                            color: '#F58B19',
                          },
                          {
                            limit: 80,
                            color: '#F5CD19',
                          },
                          {
                            color: '#5BE12C',
                          },
                        ],
                      }}
                      pointer={{
                        color: '#345243',
                        length: 0.8,
                        width: 15,
                        // elastic: true,
                      }}
                      labels={{
                        valueLabel: { formatTextValue: (value) => value + '%' },
                        tickLabels: {
                          type: 'outer',
                          defaultTickValueConfig: {
                            formatTextValue: (value) => value + '%',
                            style: { fontSize: 10 },
                          },
                        },
                      }}
                      value={
                        totalRdv === 0 && totalContact === 0
                          ? 0
                          : (totalRdv / totalContact) * 100
                      }
                      minValue={0}
                      maxValue={100}
                    />
                  )}
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Item
                sx={{
                  height: '100%',
                }}
              >
                <TitleContainer>
                  <TitleBox>Ratio présentations ({totalPresentation})</TitleBox>
                </TitleContainer>

                <Box>
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <GaugeComponent
                      type="semicircle"
                      arc={{
                        width: 0.2,
                        padding: 0.005,
                        cornerRadius: 1,
                        gradient: true,
                        subArcs: [
                          {
                            limit: 20,
                            color: '#EA4228',
                            /*  onClick: () =>
                console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'),
              onMouseMove: () =>
                console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB'),
              onMouseLeave: () =>
                console.log('CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC'), */
                          },
                          {
                            limit: 40,
                            color: '#F58B19',
                          },
                          {
                            limit: 60,
                            color: '#F58B19',
                          },
                          {
                            limit: 80,
                            color: '#F5CD19',
                          },
                          {
                            color: '#5BE12C',
                          },
                        ],
                      }}
                      pointer={{
                        color: '#345243',
                        length: 0.8,
                        width: 15,
                        // elastic: true,
                      }}
                      labels={{
                        valueLabel: { formatTextValue: (value) => value + '%' },
                        tickLabels: {
                          type: 'outer',
                          defaultTickValueConfig: {
                            formatTextValue: (value) => value + '%',
                            style: { fontSize: 10 },
                          },
                        },
                      }}
                      value={
                        totalPresentation === 0 && totalContact === 0
                          ? 0
                          : (totalPresentation / totalContact) * 100
                      }
                      minValue={0}
                      maxValue={100}
                    />
                  )}
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Item
                sx={{
                  height: '100%',
                }}
              >
                <TitleContainer>
                  <TitleBox>Ratio signatures ({totalSignature})</TitleBox>
                </TitleContainer>

                <Box>
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <GaugeComponent
                      type="semicircle"
                      arc={{
                        width: 0.2,
                        padding: 0.005,
                        cornerRadius: 1,
                        gradient: true,
                        subArcs: [
                          {
                            limit: 20,
                            color: '#EA4228',
                            /*  onClick: () =>
                console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'),
              onMouseMove: () =>
                console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB'),
              onMouseLeave: () =>
                console.log('CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC'), */
                          },
                          {
                            limit: 40,
                            color: '#F58B19',
                          },
                          {
                            limit: 60,
                            color: '#F58B19',
                          },
                          {
                            limit: 80,
                            color: '#F5CD19',
                          },
                          {
                            color: '#5BE12C',
                          },
                        ],
                      }}
                      pointer={{
                        color: '#345243',
                        length: 0.8,
                        width: 15,
                        // elastic: true,
                      }}
                      labels={{
                        valueLabel: { formatTextValue: (value) => value + '%' },
                        tickLabels: {
                          type: 'outer',
                          defaultTickValueConfig: {
                            formatTextValue: (value) => value + '%',
                            style: { fontSize: 10 },
                          },
                        },
                      }}
                      value={
                        totalSignature === 0 && totalContact === 0
                          ? 0
                          : (totalSignature / totalContact) * 100
                      }
                      minValue={0}
                      maxValue={100}
                    />
                  )}
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Item
                sx={{
                  height: '100%',
                }}
              >
                <TitleContainer>
                  <TitleBox>Liste des entreprises ({companysCount})</TitleBox>
                </TitleContainer>
                <ListTable
                  type={'entreprise'}
                  items={companys}
                  setId={setId}
                  loading={loadingEntreprise}
                  handleClick={handleClickToProfil}
                  /* handleRemove={handleRemoveProfil} */
                />
                <AppPagination
                  handlePageChange={handlePageChange}
                  count={count}
                  page={page}
                />
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Item
                sx={{
                  height: '100%',
                }}
              >
                <TitleContainer>
                  <TitleBox>
                    Listes des fiches de poste ({countBesoin})
                  </TitleBox>
                </TitleContainer>
                <BesoinTable
                  besoins={besoins}
                  loading={loadingBesoin}
                  /* handleRemove={handleRemoveBesoin}
                  handleEdit={handleEditBesoin}
                  handleDetailBesoin={handleDetailBesoin} */
                  handlePageChange={handlePageBesoinChange}
                  count={countBesoin}
                  page={pageBesoin}
                  role={'ROLE_ADMIN'}
                  showDetail={true}
                  showEdit={false}
                />
              </Item>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  )
}

export default ProfilDashboard
