import React, { useState } from 'react'
import {
  Modal,
  Box,
  IconButton,
  Typography,
  TextField,
  Grid,
  FormLabel,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import CustomButton from '../buttons/CustomButton'
import { styled } from '@mui/system'
import { faClose, faRemove } from '@fortawesome/pro-solid-svg-icons'
import lightTheme from '../../styles/theme/lightTheme'
import { BorderButtonPurple } from '../buttons'
import { TextInputClassic } from '../inputs'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'auto !important',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: 100,
}))

const SendInvoiceModal = ({ open, onClose, onSend, designation, setDesignation }) => {
  const [emails, setEmails] = useState([''])
  const [description, setDescription] = useState('')

  const handleAddEmail = () => {
    setEmails([...emails, ''])
  }

  const handleRemoveEmail = (index) => {
    const newEmails = emails.filter((_, i) => i !== index)
    setEmails(newEmails)
  }

  const handleEmailChange = (index, value) => {
    const newEmails = [...emails]
    newEmails[index] = value
    setEmails(newEmails)
  }

  const handleSubmit = () => {
    const validEmails = emails.filter((email) => email.trim() !== '')
    onSend(validEmails, description)
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          Envoyer la facture
          <Box
            sx={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon icon={faClose} fontSize={25} onClick={onClose} />
          </Box>
        </Typography>
        <BoxContainer sx={{ width: '500px' }}>
          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              marginBottom: 5,
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <Typography variant="subtitle1">Designation</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <TextInputClassic
                width={'100%'}
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
                type="text"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <Typography variant="subtitle1">Description</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <TextField
                fullWidth
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <Typography variant="subtitle1">Destinataires</Typography>
            </Grid>

            {emails.map((email, index) => (
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel key={index} className={'labelForm'}>{`Email ${
                  index + 1
                }`}</FormLabel>

                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  {/* <TextField
                    fullWidth
                    value={email}
                    onChange={(e) => handleEmailChange(index, e.target.value)}
                    type="email"
                  /> */}
                  <TextInputClassic
                    width={'100%'}
                    value={email}
                    onChange={(e) => handleEmailChange(index, e.target.value)}
                    type="email"
                  />

                  <IconButton
                    onClick={() => handleRemoveEmail(index)}
                    sx={{ alignSelf: 'center' }}
                  >
                    <FontAwesomeIcon
                      icon={faRemove}
                      fontSize={25}
                      color={lightTheme.palette.success.main}
                      cursor={'pointer'}
                    />
                  </IconButton>
                </Box>
              </Grid>
            ))}

            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <CustomButton
                displayIcon={'none'}
                handleClick={handleAddEmail}
                style={{ marginLeft: 'auto' }}
              >
                <FontAwesomeIcon icon={faPlus} />
                Ajouter un destinataire
              </CustomButton>
            </Grid>
          </Grid>
        </BoxContainer>

        <Box className={'blockButtons'}>
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            Annuler
          </BorderButtonPurple>
          <CustomButton displayIcon={'none'} handleClick={handleSubmit}>
            Envoyer
          </CustomButton>
        </Box>
      </ModalContainer>
    </Modal>
  )
}

export default SendInvoiceModal
